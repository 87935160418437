import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import backendAddress from './BackendAddress';

import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Alert, Box } from '@mui/material';

const SessionManager = ({ userData }) => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await fetch(`${backendAddress}/api/getsessions`, {
        headers: {
          'session': Cookies.get('session'),
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching sessions.');
      }

      const data = await response.json();
      setSessions(data.sessions || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setError('Error fetching sessions.');
      setLoading(false);
    }
  };

  const deleteSession = async (sessionId) => {
    try {
      const response = await fetch(`${backendAddress}/api/deletesession/${sessionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'session': Cookies.get('session'),
        },
      });

      if (!response.ok) {
        throw new Error('Error deleting session.');
      }

      fetchSessions(); // Refresh sessions after deletion
    } catch (error) {
      console.error('Error deleting session:', error);
      setError('Error deleting session.');
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Sessiju menedžeris
      </Typography>
      <Typography variant="body1" paragraph>
        Lai padarītu šo vidi drošaku, ir izveidota šada sadaļa, lai pēc paroles mainīšanas dzēstu sessiju, lai nevarētu dabūt pieeju pie esošās konta sessijas.
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Vārds</TableCell>
                <TableCell>Pēdējais autorizēšanās datums</TableCell>
                <TableCell>Pēdējā IP adrese</TableCell>
                <TableCell>Dzēst sessiju</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((session) => (
                <TableRow key={session.id}>
                  <TableCell>{session.id}</TableCell>
                  <TableCell>{session.username}</TableCell>
                  <TableCell>
                    {new Date(session.timestamp).toLocaleString('lv', {
                      timeZone: 'Europe/Riga',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false,
                    }).replace(',', '')}
                  </TableCell>
                  <TableCell>{session.ip}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => deleteSession(session.id)}
                    >
                      Dzēst
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </Container>
  );
};

export default SessionManager;