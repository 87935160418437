import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuContent from './MenuContent';
import OptionsMenu from './OptionsMenu';
import logo from '../../assets/logo.png';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu({userData, setSelected, selected, setLoggedIn}) {
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
          <img src={logo} width="68" height="57" alt="Logo" style={{paddingRight: '10px'}}/>
          <Typography variant="h6" sx={{ fontWeight: 500, lineHeight: '16px', alignSelf: 'center', fontSize: '22px' }}>
            JVĢ-Admin
          </Typography>
      </Box>
      <Divider />
      <MenuContent userData={userData} setSelected={setSelected} selected={selected}/>
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt="Riley Carter"
          src="/static/images/avatar/7.jpg"
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
            {userData.username} [Tiesības: {userData.perm_level}]
          </Typography>
          {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {userData.email}
          </Typography> */}
        </Box>
        <OptionsMenu setLoggedIn={setLoggedIn}/>
      </Stack>
    </Drawer>
  );
}