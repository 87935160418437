import React, { useEffect, useState } from 'react';
import '../css/navbar.css';

import backendAddress from '../panel_data/components/BackendAddress';

const FastAccess = () => {
    const [fastAccessElements, setFastAccessElements] = useState([]);

    useEffect(() => {
        fetch(`${backendAddress}/api/getfastaccesselements`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            const sortedData = data.sort((a, b) => a.id - b.id);
            setFastAccessElements(sortedData);
        })
        .catch(error => {
            console.error('Error fetching fast access elements:', error);
        });
    }, []);

    return (
        <div className="fast-access-container">
            <p><strong className='bold-text'>Ātrās saites:</strong></p>
            {fastAccessElements.map(element => (
                <a 
                    key={element.id} 
                    href={element.link} 
                    target={element.newTab ? "_blank" : "_self"} 
                    rel={element.newTab ? "noopener noreferrer" : undefined}
                >
                    <p>{element.name}</p>
                </a>
            ))}
        </div>
    );
};

export default FastAccess;