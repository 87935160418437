import React, { useEffect, useState } from 'react';
import backendAddress from '../panel_data/components/BackendAddress';
import { namedays } from 'vardadienas';
import { Link } from 'react-router-dom';

const EventsSmall = () => {
    const [events, setEvents] = useState([]);
    const [academicYear, setAcademicYear] = useState(null);
    const [today, setToday] = useState(new Date());
    const [tomorrow, setTomorrow] = useState('');
    const [vardaDienas, setVardaDienas] = useState('');
    const [vardaDienasRit, setVardaDienasRit] = useState('');
    const [birthdays, setBirthdays] = useState([]);
    const [todaysBirthdays, setTodaysBirthdays] = useState([]);
    const [tomorrowsBirthdays, setTomorrowsBirthdays] = useState([]);

    useEffect(() => {
        const rigaOffset = 3 * 60;
        const now = new Date();
        const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
        const rigaTime = new Date(utcNow.getTime() + (rigaOffset * 60000));

        const month = rigaTime.getMonth() + 1;
        const year = rigaTime.getFullYear();
        const academicYear = (month >= 9) ? year : year - 1;
        setAcademicYear(academicYear);
        setToday(rigaTime);
    }, []);
    
    useEffect(() => {
        const rigaOffset = 3 * 60;
        const now = new Date();
        const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
        const rigaTime = new Date(utcNow.getTime() + (rigaOffset * 60000));

        const tomorrowDate = new Date(today);
        tomorrowDate.setDate(today.getDate() + 1);

        const formatDateKey = (date) => {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${month}-${day}`;
        };

        const todayKey = formatDateKey(today);
        const tomorrowKey = formatDateKey(tomorrowDate);

        const todayNames = namedays[todayKey] ? namedays[todayKey].join(', ') : 'Nav vārda dienas';
        const tomorrowNames = namedays[tomorrowKey] ? namedays[tomorrowKey].join(', ') : 'Nav vārda dienas';

        setVardaDienas(todayNames);
        setVardaDienasRit(tomorrowNames);

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const tomorrowFormatted = tomorrowDate.toLocaleDateString('lv', options);

        setTomorrow(tomorrowFormatted);
    }, [today]);

    useEffect(() => {
        if (academicYear) {
            fetch(`${backendAddress}/api/getEvents/${academicYear}`)
                .then(response => response.json())
                .then(data => setEvents(data))
                .catch(error => console.error('Error fetching events:', error));
            fetch(`${backendAddress}/api/birthdays`)
                .then(response => response.json())
                .then(data => {
                    setBirthdays(data);
                    filterBirthdays(data);
                })
                .catch(error => console.error('Error fetching birthdays:', error));
        }
    }, [academicYear]);

    const filterBirthdays = (birthdayData) => {
        // Get today's date in the correct format (DD.MM.YYYY)
        const today = new Date();
        const todayStr = today.toLocaleDateString('en-GB');
        const tomorrowDate = new Date(today);
        tomorrowDate.setDate(today.getDate() + 1);
        const tomorrowStr = tomorrowDate.toLocaleDateString('en-GB');
    
        // Convert to the format DD.MM.YYYY
        const formattedTodayStr = todayStr.replace(/\//g, '.');
        const formattedTomorrowStr = tomorrowStr.replace(/\//g, '.');
    
        // Filter birthdays for today and tomorrow
        const todays = birthdayData.filter(birthday => birthday.date === formattedTodayStr);
        const tomorrows = birthdayData.filter(birthday => birthday.date === formattedTomorrowStr);
    
        setTodaysBirthdays(todays);
        setTomorrowsBirthdays(tomorrows);
    };

    const findEventTypes = () => {
        let lastEvent = null;
        let currentEvents = [];
        let upcomingEvent = null;
    
        const todayStr = today.toISOString().split('T')[0];
    
        for (const event of events) {
            if (event.endDate && event.date <= todayStr && todayStr <= event.endDate) {
                currentEvents.push(event);
            } else if (event.date === todayStr) {
                currentEvents.push(event);
            } else if (event.date < todayStr) {
                lastEvent = event;
            } else if (event.date > todayStr && !upcomingEvent) {
                // Next event after today
                upcomingEvent = event;
            }
        }
    
        return { lastEvent, currentEvents, upcomingEvent };
    };

    const { lastEvent, currentEvents, upcomingEvent } = findEventTypes();

    const formatEventDateRange = (startDate, endDate) => {
        const start = new Date(startDate).toLocaleDateString('lv-LV', { day: 'numeric', month: 'short' });
        const end = new Date(endDate).toLocaleDateString('lv-LV', { day: 'numeric', month: 'short' });
        return start === end ? start : `${start} - ${end}`;
    };

    const renderEventTime = (event) => {
        if (event.time && event.endTime) {
            return <p className="event-time">{formatEventTimeRange(event.time, event.endTime)}</p>;
        } else if (event.time) {
            return <p className="event-time">{event.time}</p>;
        } else {
            return <p className="event-time">Laiks nav norādīts</p>;
        }
    };
    
    const formatEventTimeRange = (time, endTime) => {
        return time === endTime ? time : `${time} - ${endTime}`;
    };

    const renderEventDetails = (event) => (
        <>
            <div className="event-date">
                <span>{event.endDate ? formatEventDateRange(event.date, event.endDate) : formatEventDateRange(event.date, event.date)}</span>
                {renderEventTime(event)}
            </div>
            <div className="event-details">
                <h3 className="event-title">{event.name}</h3>
                <p className="event-location"><i className="fa fa-map-marker"></i> {event.location}</p>
                <p className="event-location"><i className="fa fa-users"></i> {event.people}</p>
            </div>
        </>
    );
    

    return (
        <div className='zd_container'>
            <h1 className='zd_title pi_margin_bot'>Aktualitātes</h1>
            <div className='aktual_container'>
            <div className="events-container">
                    <div className="events-list">
                        <h3>Iepriekšējais pasākums:</h3>
                        <div className="event-item">
                            {lastEvent ? renderEventDetails(lastEvent) : <p>Nav iepriekšējo pasākumu</p>}
                        </div>
                        <h3>Šodien:</h3>
                        {currentEvents.length > 0 
                                ? currentEvents.map((event, index) => (
                                    <div className="event-item">
                                        <React.Fragment key={index}>
                                            {renderEventDetails(event)}
                                        </React.Fragment>
                                    </div>
                                ))
                                : <p>Šodien nav ieplānotu pasākumu</p>
                        }    
                        <h3>Nākamais pasākums:</h3>
                        <div className="event-item">
                            {upcomingEvent ? renderEventDetails(upcomingEvent) : <p>Nav turpmāk ieplānoto pasākumu</p>}
                        </div>
                    </div>
                    <center>
                        <Link to="/pasakumi" className="button">Visi pasākumi</Link>
                    </center>
                </div>
                <div className="events-container">
                    <h2 className="zd_title">Stundu laiki</h2>
                    <ul className="class-times">
                        <li><span className="period">0. stunda</span> <span className="time">08:15 - 08:55</span></li>
                        <li><span className="period">1. stunda</span> <span className="time">09:05 - 09:45</span></li>
                        <li><span className="period">2. stunda</span> <span className="time">09:55 - 10:35</span></li>
                        <li><span className="period">3. stunda</span> <span className="time">10:45 - 11:30</span></li>
                        <li><span className="period">4. stunda</span> <span className="time">11:40 - 12:20</span></li>
                        <li><span className="period">5. stunda</span> <span className="time">13:10 - 13:50</span></li>
                        <li><span className="period">6. stunda</span> <span className="time">14:00 - 14:40</span></li>
                        <li><span className="period">7. stunda</span> <span className="time">14:50 - 15:30</span></li>
                        <li><span className="period">8. stunda</span> <span className="time">15:30 - 16:20</span></li>
                    </ul>
                </div>
                <div className="events-container">
                    <h2 className="zd_title">Kalendārs</h2>
                    <p><i className='fa fa-clock' style={{paddingRight: '10px'}}/>{today.toLocaleDateString('lv', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</p>
                    <hr></hr>
                    <h5 className='bold-text'><i className='fa fa-calendar' style={{paddingRight: '10px'}}/>Vārda dienas:</h5>
                    <p><span className='bold-text'>Šodien:</span> {vardaDienas}</p>
                    <p><span className='bold-text'>Rīt:</span> {vardaDienasRit}</p>
                    <hr></hr>
                    <h5 className='bold-text'><i className='fa fa-cake-candles' style={{paddingRight: '10px'}}/>Dzimšanas dienas:</h5>
                    <div>
                        <p>
                            <span className='bold-text'>Šodien: </span>
                            {todaysBirthdays.length > 0 
                                ? todaysBirthdays.map((birthday, index) => (
                                    <span key={index}>{birthday.names.join(', ')}{index < todaysBirthdays.length - 1 ? ', ' : ''}</span>
                                ))
                                : 'Nav'}
                        </p>
                        <p>
                            <span className='bold-text'>Rīt: </span>
                            {tomorrowsBirthdays.length > 0 
                                ? tomorrowsBirthdays.map((birthday, index) => (
                                    <span key={index}>{birthday.names.join(', ')}{index < tomorrowsBirthdays.length - 1 ? ', ' : ''}</span>
                                ))
                                : 'Nav'}
                        </p>
                    </div>
                    <center>
                        <Link to="/kalendars" className="button">Kalendārs</Link>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default EventsSmall;