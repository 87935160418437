import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Height } from '@mui/icons-material';

const Gallery = () => {
    const items = [
        <p key="1"><span className="bold-text">Vīzija - </span>Jēkabpils Valsts ģimnāzija ir kvalitatīva Zemgales reģiona izglītības iestāde: tehnoloģiski aprīkota, atvērta inovācijām, radošai darbībai un tehnoloģiju izmantošanai mērķtiecīgā skolēnu, pedagogu, vecāku un dibinātāja – Jēkabpils pašvaldības – sadarbībā. Labākā skola daudzpusīgai personības attīstībai.</p>,
        <p key="2"><span className="bold-text">Misija - </span>palīdzēt skolēnam atklāt un attīstīt savas dotības, veicinot viņa izaugsmi, sagatavojot izglītības turpināšanai viņa spējām un interesēm atbilstošā pakāpē un virzienā.</p>,
        <p key="3"><span className="bold-text">Mūsu devīze</span><br />
            Zinošam, protošam -<br />
            Tam turēt tēvu zemi.<br />
            / Kronvaldu Atis /<br />
        </p>,
        <p key="4"><span className='bold-text'>Kas mēs esam - </span>Skolā mācās 422 skolēni (dati uz 01.09.2024.), strādā 50 skolotāji un 30 tehniskie darbinieki. Mēs piedāvājam: 2 izglītības programmas ar plānotiem augstākiem sasniedzamiem rezultātiem mūzikā un matemātikā pamatskolā, vispārējās vidējās izglītības programma ar 4 virzieni – matemātikas, dabaszinību, humanitārais (valodu) un humanitārais (mākslu) - ģimnāzijā; plašas iespējas interešu izglītībā: deju kolektīvi, jauktais koris, praktiskā muzicēšana, robotika u.c.</p>,
        <p key="5"><span className='bold-text'>Mūsu vēsture - </span>Ģimnāzijas dzimšanas diena ir 1919. gada 6. oktobrī un tā īpaši tiek svinēta katru gadu un vēl īpašākas ir apaļo jubileju svinības.</p>,
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500, 
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 30000,
        adaptiveHeight: false,
        Height: 400,
        arrows: false,
    };

    return (
        <div className="text-carousel-container">
            <Slider {...settings}>
                {items}
            </Slider>
        </div>
    );
};

export default Gallery;