import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SideMenuMobile from './SideMenuMobile';
import MenuButton from './MenuButton';

import logo from '../../assets/logo.png';

const Toolbar = styled(MuiToolbar)({
  width: '100%',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  gap: '12px',
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: '8px',
    p: '8px',
    pb: 0,
  },
});

const PanelNavbar = ({userData, setLoggedIn, selected, setSelected}) => {

    const handleLogout = () => {
        console.log('logout')
        setLoggedIn(false);
        Cookies.remove('session');
        window.location.reload();
    };

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };
  
    return (
      <AppBar
        position="fixed"
        sx={{
          display: { xs: 'auto', md: 'none' },
          boxShadow: 0,
          bgcolor: 'background.paper',
          backgroundImage: 'none',
          borderBottom: '1px solid',
          borderColor: 'divider',
          top: 'var(--template-frame-height, 0px)',
        }}
      >
        <Toolbar variant="regular">
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              gap: 1,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: 'center', mr: 'auto' }}
            >
              {/* <CustomIcon /> */}
              <img src={logo} width="68" height="57" alt="Logo" style={{paddingRight: '10px'}}/>
              <Typography variant="h4" component="h1" sx={{ color: 'text.primary' }}>
                JVĢ-Admin
              </Typography>
            </Stack>
            {/* <ColorModeIconDropdown /> */}
            <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuRoundedIcon />
            </MenuButton>
            <SideMenuMobile open={open} toggleDrawer={toggleDrawer} userData={userData} selected={selected} setSelected={setSelected} setLoggedIn={setLoggedIn}/>
          </Stack>
        </Toolbar>
      </AppBar>
    );
};

export default PanelNavbar;