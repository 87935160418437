import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const CustomBreadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter(x => x);

    if (location.pathname === "/" || location.pathname === "/*" || location.pathname === "/vesture") {
        return null;
    }

    const breadcrumbMap = {
        "zinas": {label: "Ziņas"},
        "notikumi": {label: "Notikumi", link: "/zinas/notikumi"},
        "latvijas-skolas-soma": { label: "Latvijas skolas soma", link: "/zinas/latvijas-skolas-soma" },
        "labie-darbi": { label: "Labie Darbi", link: "/zinas/labais-darbs" },
        "navatrasta": {label: "Nav Atrasta"},
        "meklet": {label: "Meklēt"},
        "galerija": {label: "Galerija", link: "/galerija"},
        "vesture": {label: "Vēsture"},
        "pasakumi": {label: "Pasākumi", link: "/pasakumi"},
        "sadala": {label: "Sadaļa"},
        "kalendars": {label: "Kalendārs"},
    };

    const isCustomPath = (path) => {
        return (
            path.startsWith("/lapa")
        );
    };

    return (
        <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/">
                        <i className="fas fa-arrow-left" style={{ marginRight: '5px' }}></i>
                        Sākums
                    </Link>
                </li>

                {isCustomPath(location.pathname) ? (
                    <>
                        {location.pathname.startsWith("/lapa") && (
                            <>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {pathnames[pathnames.length - 1]}
                                </li>
                            </>
                        )}
                    </>
                ) : (
                    pathnames.map((value, index) => {
                        const breadcrumb = breadcrumbMap[value] || {
                            label: value,
                            link: `/${pathnames.slice(0, index + 1).join("/")}`,
                        };

                        const isLast = index === pathnames.length - 1;

                        return (
                            <li
                                key={value}
                                className={`breadcrumb-item ${isLast ? 'active' : ''}`}
                                aria-current={isLast ? 'page' : undefined}
                            >
                                {breadcrumb.link && !isLast ? (
                                    <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
                                ) : (
                                    breadcrumb.label
                                )}
                            </li>
                        );
                    })
                )}
            </ol>
        </nav>
    );
};

export default CustomBreadcrumbs;