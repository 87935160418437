import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import backendAddress from '../panel_data/components/BackendAddress';

import LoadingAnimation from '../components/LoadingAnimation';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [staticQuery, setStaticQuery] = useState('')
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleSearch = async () => {
    setStaticQuery(query);
    if (!query) {
      setError('Tukšus lauciņus nepieņemam!');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${backendAddress}/api/searchposts?query=${query}`);
      setResults(response.data);
    } catch (error) {
      setError('Nekā neizdevās atrast!');
      setResults([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'heading':
          return (
            <h1 key={index} style={{ textAlign: block.attrs.textAlign }}>
              {block.content.map((textNode, idx) => textNode.marks?.some(mark => mark.type === 'bold') ? <strong key={idx}>{textNode.text}</strong> : <span key={idx}>{textNode.text}</span>)}
            </h1>
          );
        case 'paragraph':
          return (
            <p key={index} style={{ textAlign: block.attrs.textAlign }}>
              {block.content.map((textNode, idx) => textNode.marks?.some(mark => mark.type === 'bold') ? <strong key={idx}>{textNode.text}</strong> : <span key={idx}>{textNode.text}</span>)}
            </p>
          );
        default:
          return null;
      }
    });
  };

  const goToContent = (post) => {
    const targetUrl = 
      post.source === 'raksti' ? `/ej-uz-rakstu/${post.id}` :
      post.source === 'galerija' ? `/galerija/${post.id}` :
      `/ej-uz-lapu/${post.id}`;
      
    window.location.href = targetUrl;
  };

  const renderResult = (post) => {
    let thumbnailUrl;

    if (post.source === 'galerija' && typeof post.thumbnailUrl === 'string') {
      try {
        thumbnailUrl = JSON.parse(post.thumbnailUrl);
      } catch (error) {
        console.error("Invalid JSON format for thumbnailUrl:", error);
        thumbnailUrl = null;
      }
    } else {
      thumbnailUrl = post.thumbnailUrl;
    }
  
    const imgURL = post.source === 'raksti' ? post.thumbnailUrl :
                   post.source === 'galerija' && thumbnailUrl && thumbnailUrl.length > 0 ? 
                   `${backendAddress}/public${thumbnailUrl[0]}` :
                   '/path/to/fallback-image.png';
  
    return (
      <div
        className="news-item"
        key={post.id}
        onClick={() => goToContent(post)}
        style={styles.newsItem}
      >
        <img
          className="news-image"
          src={imgURL}
          alt={`${post.source}-${post.id}`}
          style={styles.newsImage}
        />
        <div className="news-content" style={styles.newsContent}>
          <h2 style={styles.title}>{post.title}</h2>
          <p style={styles.source}><strong className="bold-text">Sadaļa:</strong> {post.source}</p>
          <p style={styles.date}>
            <strong className="bold-text">Publicēts:</strong> {new Date(post.postedTimestamp).toLocaleDateString('lv-LV', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })} {new Date(post.postedTimestamp).toLocaleTimeString('lv-LV', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </p>
          <p style={styles.author}><strong className="bold-text">Autors:</strong> {post.author}</p>
        </div>
      </div>
    );
  };
  
  return (
    <div className='zd_container_2' style={{ marginTop: '30px', marginBottom: '30px', minHeight: '50vh' }}>
      <Helmet>
        <title>Meklēt mājaslapā - {staticQuery} | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      <div className="zd_full">
        <h1 className='zd_title pi_margin_bot'>Meklēt Mājaslapā</h1>
        <div className="search-bar">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Meklēt pēc nosaukuma..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  handleSearch();
              }
            }}
          />
          <button onClick={handleSearch} className='button-search'>Meklēt</button>
        </div>
        <hr></hr>
        {loading && <LoadingAnimation />}
        {error && <p className="error">{error}</p>}
        <div className="zd_notik_column">
          {results.length > 0 ? results.map(renderResult) : <p>Nav pieejamu rezultātu</p>}
        </div>
      </div>
      <div aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (page > 1) setPage(page - 1); }}>Iepriekšējā</a>
              </li>
              {[...Array(totalPages).keys()].map(p => (
                <li key={p + 1} className={`page-item ${page === p + 1 ? 'active' : ''}`}>
                  <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setPage(p + 1); }}>{p + 1}</a>
                </li>
              ))}
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (page < totalPages) setPage(page + 1); }}>Nākošā</a>
              </li>
            </ul>
          </div>
    </div>
  );
};
  
const styles = {
  newsItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  newsImage: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginRight: '10px'
  },
  newsContent: {
    flex: 1
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0 0 5px'
  },
  date: {
    fontSize: '14px',
    color: '#666'
  },
  source: {
    fontSize: '14px',
    color: '#666',
  },
  author: {
    fontSize: '14px',
    color: '#999'
  }
};

export default Search;