import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import backendAddress from '../panel_data/components/BackendAddress';
import LoadingAnimation from '../components/LoadingAnimation';

const EjUz = ({ type }) => {
  const location = useLocation();
  const { id } = useParams();
  const [contentResponse, setContentResponse] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${backendAddress}/api/getlink/${type}/${id}`, {
            params: { originalURL: window.location.origin },
        });
        setContentResponse(response.data);
        setRedirectUrl(response.data.link);
        console.log(response.data.link)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNotFound(true);
        } else {
          console.error('Error fetching page content:', error);
        }
      }
    };

    fetchContent();
  }, [type, id]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  }, [redirectUrl]);

  if (notFound) {
    return <Navigate to="/navatrasta" />;
  }

  return (
    <div
      className='zd_container_2'
      style={{
        marginTop: '30px',
        marginBottom: '30px',
        padding: '20px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        borderRadius: '8px',
      }}
    >
      <Helmet>
        <title>Pāriešana no īsās saites... | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      <LoadingAnimation />
    </div>
  );
};

export default EjUz;