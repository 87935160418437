import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backendAddress from './BackendAddress';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';

const BirthdayUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [disabledMainBtn, setDisabledMainBtn] = useState(true);

  useEffect(() => {
    if (selectedFile !== null) {
      if (selectedFile.name.endsWith('.csv')) {
        setDisabledMainBtn(false);
      } else {
        setDisabledMainBtn(true);
      }
    } else {
      setDisabledMainBtn(true);
    }
  }, [selectedFile]);

  const handleFileUpload = () => {
    if (!selectedFile) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post(`${backendAddress}/api/uploadBirthdays`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      setLoading(false);
      setSnackbarOpen(true);
    })
    .catch(error => {
      setLoading(false);
      setErrorMessage(error.response?.data?.message || 'Error uploading file');
    });
  };

  return (
    <Container>
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>Dzimšanas dienas</Typography>
        
        <Box sx={{ maxWidth: 500, mx: 'auto', mb: 4 }}>
          <Typography variant="h6" gutterBottom>Augšupielādēt failu (CSV)</Typography>
          <TextField
            type="file"
            inputProps={{ accept: '.csv' }}
            fullWidth
            onChange={(e) => setSelectedFile(e.target.files[0])}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleFileUpload}
            disabled={loading || disabledMainBtn}
          >
            {loading ? <CircularProgress size={24} /> : 'Augšupielādēt failu'}
          </Button>
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>{errorMessage}</Typography>
          )}
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">Fails veiksmīgi augšupielādēts!</Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default BirthdayUpload;