import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      style={{
        display: visible ? 'flex' : 'none',
        position: 'fixed',
        bottom: '25px',
        right: '25px',
        width: '70px',
        height: '70px',
        backgroundColor: '#6e0b0d',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.8)',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
      }}
      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6e0b0d')}
      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#6e0b0d')}
      onFocus={(e) => (e.currentTarget.style.backgroundColor = '#6e0b0d')}
      onBlur={(e) => (e.currentTarget.style.backgroundColor = '#6e0b0d')}
    >
      <i className="fas fa-arrow-up"></i>
    </button>
  );
};

export default ScrollToTopButton;