import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { TextField, Button, Container, Typography, Divider, Box } from '@mui/material';
import EditorComponent from './Editor';
import backendAddress from './BackendAddress';

//context
import { useHtmlEditor } from './ShowHtmlContext';

const AddNewPage = ({ userData }) => {
  const [contentJson, setContentJson] = useState(null);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const [incorrectSyntax, setIncorrectSyntax] = useState(false);
  const [syntaxError, setSyntaxError] = useState('');
  const [disabledMainBtn, setDisabledMainBtn] = useState(true);
  const [disabledLinkBtn, setDisabledLinkBtn] = useState(true);

  const generateLinkFromTitle = () => {
    if (title) {
      const specialCharsMap = {
        ā: 'a', ķ: 'k', š: 's', ģ: 'g', ē: 'e', ī: 'i', ū: 'u', ō: 'o',
        ļ: 'l', č: 'c', ņ: 'n', ž: 'z',
      };
  
      const normalizedTitle = title
        .toLowerCase()
        .replace(/[āķšģēīūōļčņž]/g, match => specialCharsMap[match] || '');
  
      const generatedLink = normalizedTitle
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-_]/g, '')
  
      setLink(generatedLink);
    }
  };

  //context
  const { showHtmlEditor, setShowHtmlEditor } = useHtmlEditor();

  useEffect(() => {
    setDisabledLinkBtn(title === '');
  
    const linkPattern = /^[a-zA-Z0-9-_]+$/;
  
    if (link && !linkPattern.test(link)) {
      setIncorrectSyntax(true);
      setDisabledMainBtn(true);
      setDisabledLinkBtn(link  !== '');
      setSyntaxError('Nepareiza sintakse! Piemērs: jvg-vesture');
    } else {
      setIncorrectSyntax(false);
      setSyntaxError('');
      setDisabledLinkBtn(link  !== '');
  
      setDisabledMainBtn(!(link && title !== ''));
    }
  }, [title, link]);

  const handleSave = async () => {
    console.log(contentJson);
    try {
      const response = await axios.post(`${backendAddress}/api/addpost`, {
        title: title,
        link: link,
        content: contentJson,
        author: userData.username,
      }, {
        headers: {
          'Author': userData.username,
        },
      });
      alert('Lapa veiksmīgi izveidota!');
      console.log('Post saved successfully:', response.data);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        alert(`Error saving post: ${error.response.data.message || error.response.statusText}`);
        console.error('Error saving post:', error.response.data);
      } else {
        alert(`Error saving post: ${error.message}`);
        console.error('Error saving post:', error);
      }
    }
  };

  return (
    <Container>
      <Box mt={14}>
        <Typography variant="h4" gutterBottom>
          Pievienot jaunu lapu
        </Typography>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Lapas nosaukums"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Ievadiet lapas nosaukumu šeit."
            variant="outlined"
            margin="normal"
            required
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Paštaisītais link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Ievadiet link šeit."
            variant="outlined"
            margin="normal"
            required
            color={incorrectSyntax ? 'error' : 'primary'}
            error={incorrectSyntax}
            helperText={syntaxError}
          />
          <Button type="submit" color='primary' fullWidth variant="contained" disabled={disabledLinkBtn} onClick={generateLinkFromTitle}>
              Uzģenerēt link no nosaukuma
          </Button>
        </Box>

        <Typography variant="body2" color="textSecondary">
          Lai paštaisītais link strādātu, lūgums ievadīt to korrekti. Piemērs: jvg-vesture
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Nekādi lielie burti, simboli un atstarpes.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="body1">
          Ievadiet jaunas lapas saturu zemāk un saglabājiet izmaiņas.
        </Typography>

        <Box mt={2}>
          <EditorComponent setContentJson={setContentJson} contentJson={contentJson} />
        </Box>

        <Divider sx={{ my: 3 }} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 3 }}
          disabled={showHtmlEditor || disabledMainBtn}
        >
          Saglabāt lapu
        </Button>
      </Box>
    </Container>
  );
};

export default AddNewPage;