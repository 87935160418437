// src/tiptap/customNodes.js
import { Node } from '@tiptap/core';
import '../css/main.css';

const ButtonNode = Node.create({
  name: 'buttonNode',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      link: {
        default: null,
      },
      openInNewTab: {
        default: false,
      },
      type: {
        default: 'standard',
      },
      text: {
        default: 'Button',
      },
      alignment: {
        default: 'left',
      },
    };
  },

  parseHTML() {
    return [{ tag: 'buttonNode' }];
  },

  renderHTML({ HTMLAttributes }) {
    const { link, openInNewTab, type, text } = HTMLAttributes;
    const target = openInNewTab ? '_blank' : '_self';

    return [
      'div',
      { class: 'button-container' },
      [
        'a',
        { href: link, target: target },
        [
          'button',
          { class: `${type === 'big' ? 'big-button' : 'button'}` },
          // Only render the icon if the button type is 'big'
          ...(type === 'big'
            ? [['i', { class: 'fa fa-link', style: 'margin-right: 10px;' }]] // Icon with margin for big button
            : []),
          text, // Add button text after the icon
        ],
      ],
    ];
  },
});

export { ButtonNode };