// src/Pasakumi.js
import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import backendAddress from '../panel_data/components/BackendAddress';

const Pasakumi = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getCurrentAcademicYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        const startOfAcademicYear = new Date(year, 8, 1);
        const endOfAcademicYear = new Date(year + 1, 7, 31);
        return now >= startOfAcademicYear && now <= endOfAcademicYear ? year : year - 1;
    };

    useEffect(() => {
        const fetchEvents = async () => {
            const academicYear = getCurrentAcademicYear();
            try {
                const response = await fetch(`${backendAddress}/api/getEvents/${academicYear}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setEvents(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchEvents();
    }, []);

    if (loading) return <p className="loading">Loading events...</p>;
    if (error) return <p className="error">Error: {error.message}</p>;

    return (
        <div className="pasakumi-container">
            <h1>Pasākumi šajā akadēmiskajā gadā</h1>
            <VerticalTimeline>
                {events.map((event) => (
                    <VerticalTimelineElement
                        key={event.id}
                        date={new Date(event.date).toLocaleDateString()}
                        iconStyle={{ background: '#6e0b0d', color: '#fff' }}
                        contentStyle={{ background: '#fff', color: '#6e0b0d' }}
                        contentArrowStyle={{ borderRight: '7px solid #6e0b0d' }}
                    >
                        <h3 className="vertical-timeline-element-title">{event.name}</h3>
                        <h4 className="vertical-timeline-element-subtitle">Laiks: {event.time || 'Nav norādīts'}</h4>
                        <p><strong>Cilvēki:</strong> {event.people}</p>
                        <p><strong>Vieta:</strong> {event.location}</p>
                        <p><strong>Papildus Informācija:</strong> {event.additionalInfo}</p>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </div>
    );
};

export default Pasakumi;