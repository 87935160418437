import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import backendAddress from './BackendAddress';

import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const EditFastAccessElements = () => {
  const [fastAccessElements, setFastAccessElements] = useState([]);

  useEffect(() => {
    fetch(`${backendAddress}/api/getfastaccesselements`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const sortedData = data.sort((a, b) => a.id - b.id);
        setFastAccessElements(sortedData);
      })
      .catch(error => {
        console.error('Error fetching fast access elements:', error);
      });
  }, []);

  const handleChange = (index, key, value) => {
    let updatedElements = [...fastAccessElements];

    if (key === 'id') {
      const newId = parseInt(value);
      const conflictingElementIndex = updatedElements.findIndex(el => el.id === newId);

      if (conflictingElementIndex !== -1 && conflictingElementIndex !== index) {
        const temp = updatedElements[conflictingElementIndex];
        updatedElements[conflictingElementIndex] = { ...updatedElements[conflictingElementIndex], id: updatedElements[index].id };
        updatedElements[index] = { ...updatedElements[index], id: newId };
      } else {
        updatedElements[index] = { ...updatedElements[index], id: newId };
      }

      updatedElements = updatedElements.sort((a, b) => a.id - b.id);
    } else {
      updatedElements[index] = { ...updatedElements[index], [key]: value };
    }

    setFastAccessElements(updatedElements);
  };

  const addNewElement = () => {
    const newId = fastAccessElements.length > 0
      ? Math.max(...fastAccessElements.map(el => el.id)) + 1
      : 0;

    const newElement = {
      id: newId,
      link: "https://jususaite.com",
      name: "Jauns Elements",
      newTab: false
    };

    setFastAccessElements([...fastAccessElements, newElement]);
  };

  const saveChanges = () => {
    fetch(`${backendAddress}/api/savefastaccesselements`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ updatedElements: fastAccessElements })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        alert('Fast Access Elements: izmaiņas veiksmīgi saglabātas!');
      })
      .catch(error => {
        console.error('Error saving fast access elements:', error);
        alert('Fast Access Elements: Neizdevās saglabāt izmaiņas!');
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const handleDelete = (index) => {
    const updatedElements = [...fastAccessElements];
    updatedElements.splice(index, 1);
    const reIndexedElements = updatedElements.map((element, newIndex) => ({
      ...element,
      id: newIndex
    }));
    setFastAccessElements(reIndexedElements);
  };

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-4 content-area">
      <div className="pt-3">
        <Typography variant="h4" gutterBottom>
          Mainīt fast access elementus
        </Typography>

        <Button variant="contained" color="primary" onClick={addNewElement} style={{ marginBottom: '20px' }}>
          Pievienot jaunu elementu
        </Button>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Saite</TableCell>
                <TableCell>Vārds</TableCell>
                <TableCell>Jaunā cilnē?</TableCell>
                <TableCell>Dzēst?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fastAccessElements.map((element, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="number"
                      value={element.id}
                      onChange={(e) => handleChange(index, 'id', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={element.link}
                      onChange={(e) => handleChange(index, 'link', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={element.name}
                      onChange={(e) => handleChange(index, 'name', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={element.newTab}
                      onChange={(e) => handleChange(index, 'newTab', e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton color="error" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <hr />

        <Typography variant="h6" gutterBottom>
          Priekšskats:
        </Typography>
        <div className="fast-access-container">
                    <p style={{paddingRight: '5px'}}><strong>Ātrās saites:</strong></p>
                    {fastAccessElements.map(element => (
                        <a 
                            key={element.id} 
                            href={element.link} 
                            target={element.newTab ? "_blank" : "_self"} 
                            rel={element.newTab ? "noopener noreferrer" : undefined}
                        >
                            <p style={{paddingLeft: '15px'}}>{element.name}</p>
                        </a>
                    ))}
                </div>

        <hr />

        <Button variant="contained" color="primary" onClick={saveChanges}>
          Saglabāt izmaiņas
        </Button>
      </div>
    </main>
  );
};

export default EditFastAccessElements;