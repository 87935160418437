import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TiptapRenderer from '../components/PageRenderer';
import backendAddress from '../panel_data/components/BackendAddress';
import LoadingAnimation from '../components/LoadingAnimation';
import ImageControllerLighthouse from '../components/ImageControllerLighthouse';

const RenderRakstus = ({ NotificationManager }) => {
    const location = useLocation();
    const [contentJson, setContentJson] = useState(null);
    const [contentResponse, setContentResponse] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            let tempPageType = '';
            if (location.pathname.startsWith("/zinas/notikumi")) {
                tempPageType = 'notikumi';
            } else if (location.pathname.startsWith("/zinas/latvijas-skolas-soma")) {
                tempPageType = 'latvijas_skolas_soma';
            } else if (location.pathname.startsWith("/zinas/labie-darbi")) {
                tempPageType = 'labais_darbs';
            }
            try {
                const response = await axios.get(`${backendAddress}/api/getraksts/${id}/${tempPageType}`);
                setContentResponse(response.data);
                if (response.data.content && response.data.content.content) {
                    setContentJson(response.data.content);
                    setImages(extractImages(response.data.content));
                } else {
                    console.error("Content structure is not as expected:", response.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setNotFound(true);
                } else {
                    console.error('Error fetching page content:', error);
                }
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchContent();
    }, [id]);

    useEffect(() => {
        if (contentJson) {
            const numericId = parseInt(id, 10);
            if (Number.isInteger(numericId)) {
                let newLink;
                if (location.pathname.startsWith("/zinas/notikumi")) {
                    newLink = `${window.location.origin}/zinas/notikumi/${contentResponse.link}`;
                } else if (location.pathname.startsWith("/zinas/latvijas-skolas-soma/")) {
                    newLink = `${window.location.origin}/zinas/latvijas-skolas-soma/${contentResponse.link}`;
                } else if (location.pathname.startsWith("/zinas/labie-darbi")) {
                    newLink = `${window.location.origin}/zinas/labie-darbi/${contentResponse.link}`;
                }
                window.history.pushState({}, '', newLink);
            }
        }
    }, [contentJson, id]);

    if (notFound) {
        return <Navigate to="/navatrasta" />;
    }

    const { title, author, published, sadala, thumbnailUrl } = contentResponse || {};

    const extractImages = (content) => {
        let imageLinks = [];

        const findImages = (node) => {
            if (Array.isArray(node)) {
                node.forEach(findImages);
            } else if (node && typeof node === 'object') {
                if (node.type === 'image' && node.attrs && node.attrs.src) {
                    imageLinks.push(node.attrs.src);
                }
                Object.values(node).forEach(findImages);
            }
        };

        findImages(content);
        console.log(imageLinks);
        return imageLinks;
    };

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const CopyLinkToClipboard = () => {
        let linkToCopy;
        if (location.pathname.startsWith("/zinas/notikumi")) {
            linkToCopy = `${window.location.origin}/ej-uz-rakstu/${contentResponse.id}`;
        } else if (location.pathname.startsWith("/zinas/latvijas-skolas-soma/")) {
            linkToCopy = `${window.location.origin}/ej-uz-rakstu/${contentResponse.id}`;
        } else if (location.pathname.startsWith("/zinas/labie-darbi")) {
            linkToCopy = `${window.location.origin}/ej-uz-rakstu/${contentResponse.id}`;
        }
        navigator.clipboard.writeText(linkToCopy)
            .then(() => {
                console.log('Link copied to clipboard:', linkToCopy);
                NotificationManager.success('Saite ir veiksmīgi nokopēta!', 'Īsā saite');
            })
            .catch(err => {
                console.error('Failed to copy the link: ', err);
                NotificationManager.error('Neizdevās nokopēt saiti!', 'Īsā saite');
            });
    };

    const formatDateInRigaTime = (dateString) => {
      // Ensure dateString is defined and a valid date
      if (!dateString || isNaN(Date.parse(dateString))) {
        console.error('Invalid date:', dateString);
        return 'Nezināms datums';
      }
    
      const date = new Date(dateString);
    
      const options = {
        timeZone: 'Europe/Riga',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
    
      const formatter = new Intl.DateTimeFormat('lv', options);
      return formatter.format(date);
    };

    return (
        <div className='zd_container_2' style={{ marginTop: '30px', marginBottom: '30px', padding: '20px', boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '8px' }}>
            <Helmet>
                <title>{isLoading ? ('Ielādē rakstu...') : (title)} | Jēkabpils Valsts ģimnāzija</title>
            </Helmet>
            {isLoading ? (
                <LoadingAnimation />
            ) : (
                <div>
                    <div className="raksts-header">
                        <img src={thumbnailUrl} alt={title} style={{ width: '130px', maxHeight: '400px', objectFit: 'cover', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '30px', marginRight: '20px' }} />
                        <h1>
                            {title}
                        </h1>
                        <hr style={{ margin: '20px 0' }} />
                    </div>
                    <div className="raksts-content">
                        <TiptapRenderer contentJson={contentJson} onImageClick={handleImageClick} />
                    </div>
                    <div style={{ marginTop: '50px', borderTop: '1px solid #ddd', paddingTop: '30px' }}>
                        <p style={{ margin: '0 0 5px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Publicēts:</span> {formatDateInRigaTime(published)}</p>
                        <p style={{ margin: '0 0 5px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Kategorija:</span> {sadala}</p>
                        <p style={{ margin: '0 0 15px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Autors:</span> {author}</p>
                        <p style={{ margin: '0 0 5px', fontSize: '16px' }}>
                            <a onClick={CopyLinkToClipboard} style={{ cursor: 'pointer', color: '#6e0b0d', textDecoration: 'underline' }}>
                                Īsā saite
                            </a>
                        </p>
                    </div>
                </div>
            )}
            <ImageControllerLighthouse
                images={images}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
        </div>
    );
};

export default RenderRakstus;