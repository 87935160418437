import React, { useEffect, useState } from 'react';
import '../css/navbar.css';
import { namedays } from 'vardadienas';

const Scroll = () => {
    const [today, setToday] = useState('');
    const [tomorrow, setTomorrow] = useState('');
    const [vardaDienas, setVardaDienas] = useState('');
    const [vardaDienasRit, setVardaDienasRit] = useState('');

    useEffect(() => {
        const rigaOffset = 3 * 60;
        const now = new Date();
        const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
        const rigaTime = new Date(utcNow.getTime() + (rigaOffset * 60000));

        const todayDate = new Date(rigaTime);
        const tomorrowDate = new Date(todayDate);
        tomorrowDate.setDate(todayDate.getDate() + 1);

        const formatDateKey = (date) => {
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${month}-${day}`;
        };

        const todayKey = formatDateKey(todayDate);
        const tomorrowKey = formatDateKey(tomorrowDate);

        const todayNames = namedays[todayKey] ? namedays[todayKey].join(', ') : 'Nav vārda dienas';
        const tomorrowNames = namedays[tomorrowKey] ? namedays[tomorrowKey].join(', ') : 'Nav vārda dienas';

        setVardaDienas(todayNames);
        setVardaDienasRit(tomorrowNames);

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const todayFormatted = todayDate.toLocaleDateString('lv', options);
        const tomorrowFormatted = tomorrowDate.toLocaleDateString('lv', options);

        setToday(todayFormatted);
        setTomorrow(tomorrowFormatted);
    }, []);

    return (
        <div className="scroll-container">
            <div className="scroll-text">
                <span className='bold-text' style={{paddingRight: '70px'}}><i className='fa fa-clock' style={{paddingRight: '10px'}}/>{today}</span>
                <span className='bold-text' style={{paddingRight: '70px'}}><i className='fa fa-calendar' style={{paddingRight: '10px'}}/>Vārda dienas</span>
                <span style={{paddingRight: '70px'}}>Šodien: {vardaDienas}</span>
                <span style={{paddingRight: '70px'}}>Rīt: {vardaDienasRit}</span>
                <span className='bold-text' style={{paddingRight: '70px'}}><i className='fa fa-cake-candles' style={{paddingRight: '10px'}}/>Dzimšanas dienas</span>
                <span style={{paddingRight: '70px'}}>Šodien: Nav</span>
                <span style={{paddingRight: '70px'}}>Rīt: Nav</span>
            </div>
        </div>
    );
};

export default Scroll;