import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

import EditorComponent from './Editor';
import backendAddress from './BackendAddress';

import { Container, Typography, List, ListItem, ListItemText, Divider, Button, TextField, Box, Pagination, CircularProgress, Alert } from '@mui/material';

//context
import { useHtmlEditor } from './ShowHtmlContext';

const EditRaksts = ({ userData }) => {
    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState(null);
    const [contentJson, setContentJson] = useState(null);
    const [title, setTitle] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);

    //context
    const { showHtmlEditor, setShowHtmlEditor } = useHtmlEditor();

    useEffect(() => {
        fetchPages();
    }, [currentPage]);

    const fetchPages = () => {
        setLoading(true);
        fetch(`${backendAddress}/api/allraksti?page=${currentPage}&limit=10&search=${searchTerm}`, {
            headers: {
                'session': Cookies.get('session')
            }
        })
        .then(response => response.json())
        .then(data => {
            setPages(data.lapas || []);
            setTotalPages(data.totalPages || 0);
            setLoading(false);
        })
        .catch(error => {
            setError('Error fetching articles.');
            setLoading(false);
        });
    };

    const handleSelectPage = (page) => {
        setSelectedPage(page);
        setContentJson(page.content);
        setTitle(page.title);
    };

    const handleSavePage = async () => {
        try {
            const response = await axios.post(`${backendAddress}/api/saveraksts`, {
                pageId: selectedPage.id,
                title: title, // Save the edited title
                timestamp: selectedPage.published,
                content: contentJson,
                executor: userData.username
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Author': userData.username,
                }
            });
            alert('Raksts veiksmīgi atjaunots!');
            window.location.reload();
        } catch (error) {
            alert('Notikusi kļūda raksta atjaunošanā!');
        }
    };

    const handleDeletePage = async () => {
        try {
            const response = await axios.post(`${backendAddress}/api/deleteraksts`, {
                pageId: selectedPage.id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Author': userData.username,
                }
            });
            alert('Raksts veiksmīgi izdzēsts!');
            window.location.reload();
        } catch (error) {
            alert('Notikusi kļūda raksta dzēšanā!');
        }
    };

    return (
        <Container>
            <Box mt={14}>
                <Typography variant="h4" gutterBottom>
                    Rediģēt Eksistējošos Rakstus
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Izvēlaties rakstu, kuru vēlaties rediģēt:
                </Typography>
            </Box>

            {/* Search Input */}
            <TextField
                fullWidth
                label="Meklēt konkrētus rakstus"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="outlined"
                margin="normal"
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={fetchPages}>
                Meklēt
            </Button>

            <Divider sx={{ my: 3 }} />

            {/* Pagination */}
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                <List>
                    {pages.map((page) => (
                        <ListItem 
                            key={page.id} 
                            button 
                            selected={selectedPage && selectedPage.id === page.id}
                            onClick={() => handleSelectPage(page)}
                        >
                            <ListItemText primary={page.title} />
                        </ListItem>
                    ))}
                </List>
            )}

            <Box mt={3} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                />
            </Box>

            <Divider sx={{ my: 3 }} />

            {selectedPage && (
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        Rediģēt rakstu: {selectedPage.title}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Raksta nosaukums"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Ievadiet raksta nosaukumu šeit."
                        variant="outlined"
                        margin="normal"
                    />
                    <Divider sx={{ my: 3 }} />

                    <EditorComponent key={selectedPage.id} setContentJson={setContentJson} contentJson={contentJson} />
                    <Divider sx={{ my: 3 }} />

                    <Box display="flex" justifyContent="space-between">
                        <Button variant="contained" color="primary" onClick={handleSavePage} disabled={showHtmlEditor}>
                            Saglabāt rakstu
                        </Button>
                        <Button variant="contained" color="error" onClick={handleDeletePage}>
                            Dzēst rakstu
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default EditRaksts;