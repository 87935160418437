import React, { createContext, useContext, useState } from 'react';

const HtmlEditorContext = createContext();

export const HtmlEditorProvider = ({ children }) => {
  const [showHtmlEditor, setShowHtmlEditor] = useState(false);

  return (
    <HtmlEditorContext.Provider value={{ showHtmlEditor, setShowHtmlEditor }}>
      {children}
    </HtmlEditorContext.Provider>
  );
};

export const useHtmlEditor = () => useContext(HtmlEditorContext);