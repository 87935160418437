import React from "react";
import { Helmet } from 'react-helmet';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { motion } from "framer-motion";
import { FaSchool, FaGraduationCap, FaStar } from "react-icons/fa"; 
import '../css/vesture.css';

import skolasvesture from '../assets/SkolasVesture/SKOLAVEC.jpg'

const Vesture = () => {
  const events = [
    {
      year: "1919. gada 6.oktobrī",
      title: "Skola Dibināta",
      description: "Skola dibināta kā pirmā reāltipa mācību iestāde apriņķī.",
      icon: <FaSchool />,
      image: skolasvesture,
    },
    {
      year: "1922.-1923. m.g.",
      title: "Komercnodaļa",
      description: "Vidusskolā atvērta komercnodaļa.",
      icon: <FaGraduationCap />,
      image: skolasvesture,
    },
    {
      year: "1927.-1928. m.g.",
      title: "Komercnodaļas Pārorganizēšana",
      description: "Komercnodaļa pārorganizēta par komercskolu.",
      icon: <FaStar />,
      image: skolasvesture,
    },
    {
      year: "1932.-1939. gadā un 1941.-1944. gadā",
      title: "Jēkabpils Valsts Ģimnāzija",
      description: "Skola kļūst par Jēkabpils Valsts ģimnāziju.",
      icon: <FaSchool />,
      image: skolasvesture,
    },
    {
      year: "1948. gadā",
      title: "Atdalīšanās no Komercskolas",
      description: "Vidusskola pilnībā atdalās no komercskolas.",
      icon: <FaGraduationCap />,
      image: skolasvesture,
    },
    {
      year: "1950.-1951. m.g.",
      title: "Jēkabpils 1. Vidusskolas Nosaukums",
      description: "Skola iegūst Jēkabpils 1. vidusskolas nosaukumu, pievienota Krasta skolas ēka.",
      icon: <FaStar />,
      image: skolasvesture,
    },
    {
      year: "1966. gadā",
      title: "Pirmā Piebūve",
      description: "Uzcelta pirmā piebūve.",
      icon: <FaSchool />,
      image: skolasvesture,
    },
    {
      year: "1979. gadā",
      title: "Otrā Piebūve",
      description: "Uzcelta otrā piebūve.",
      icon: <FaGraduationCap />,
      image: skolasvesture,
    },
    {
      year: "1982. gadā",
      title: "Sporta Korpuss",
      description: "Nodots ekspluatācijā sporta korpuss.",
      icon: <FaStar />,
      image: skolasvesture,
    },
    {
      year: "1990. gadā",
      title: "Atjaunots Skolas Karogs",
      description: "Atjaunots Jēkabpils Valsts ģimnāzijas un komercskolas karogs ar uzrakstu “Mūsu darbs un zinašanas - Tēvzemei”.",
      icon: <FaSchool />,
      image: skolasvesture,
    },
    {
      year: "1993. gadā jūnijā",
      title: "Akreditācija - Jēkabpils Ģimnāzija",
      description: "Skola akreditācijas rezultātā kļūst par Jēkabpils ģimnāziju.",
      icon: <FaGraduationCap />,
      image: skolasvesture,
    },
    {
      year: "1994. gadā septembrī",
      title: "Jēkabpils Sākumskolas Dibināšana",
      description: "Nodibināta Jēkabpils sākumskola, no ģimnāzijas pakāpeniski atdalās sākumskolas klases. Savas pastāvēšanas 75 gados skolai ir bijis 71 izlaidums, skolu beiguši vairak nekā 3000 audzēkņu.",
      icon: <FaStar />,
      image: skolasvesture,
    },
    {
      year: "1996.gadā",
      title: "Valsts Ģimnāzijas Statuss",
      description: "Ģimnāzija iegūst Valsts ģimnāzijas statusu.",
      icon: <FaSchool />,
      image: skolasvesture,
    },
    {
      year: "2002. gadā vasarā",
      title: "Skolas Renovācija",
      description: "Veikta skolas renovācija.",
      icon: <FaGraduationCap />,
      image: skolasvesture,
    },
  ];

  return (
    <div className="vesture_container">
      <Helmet>
        <title>Skolas vēsture | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      <motion.div
        className="timeline-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 className="timeline-header">Jēkabpils Valsts ģimnāzijas vēsture</h1>
        <VerticalTimeline>
          {events.map((event, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              date={event.year}
              iconStyle={{
                background: "#6e0b0d",
                color: "#fff",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
              }}
              contentStyle={{
                background: "#6e0b0d",
                color: "#fff",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px #6e0b0d",
              }}
              contentArrowStyle={{ borderRight: "7px solid #6e0b0d" }}
              icon={
                <motion.div
                  style={{ width: '24px', height: '24px' }}
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1.2 }}
                  transition={{ type: "spring", stiffness: 120 }}
                >
                  {event.icon}
                </motion.div>
              }
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.2 }}
                transition={{ duration: 0.8 }}
              >
                <h3 className="vertical-timeline-element-title">{event.title}</h3>
                <p className="vertical-timeline-element-description">
                  {event.description}
                </p>
                <div className="timeline-image-container">
                  <img 
                    src={event.image} 
                    alt={event.title} 
                    className="timeline-image" 
                  />
                </div>
              </motion.div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </motion.div>
    </div>
  );
};

export default Vesture;