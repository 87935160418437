import { useEffect, useState } from 'react';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import backendAddress from '../panel_data/components/BackendAddress';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [footerElements, setFooterElements] = useState([]);

    useEffect(() => {
        fetch(`${backendAddress}/api/getfooterelements`)
            .then(response => response.json())
            .then(data => setFooterElements(data))
            .catch(error => console.error('Error fetching footer elements:', error));
    }, []);

    return (
        <div className="footer2">
            <div className="sb_footer section_padding">
                <div className='sb_footer-links'>
                        {footerElements.map(element => (
                            <div key={element.id}>
                                <div className='sb_footer-links-div'>
                                <h4>{element.headerName}</h4>
                                {element.elements.map(subelement => (
                                    <a
                                        key={subelement.id}
                                        href={subelement.link}
                                        target={subelement.newTab ? "_blank" : "_self"}
                                        rel={subelement.newTab ? "noopener noreferrer" : undefined}
                                    >
                                        <p>{subelement.name}</p>
                                    </a>
                                ))}
                                </div>
                            </div>
                        ))}
                      <div className='sb_footer-links-div'>
                        <h4>Kontakti</h4>
                        <p>
                          <i className="fas fa-phone" style={{paddingRight: '10px'}}></i>
                          +371 65237551
                        </p>
                        <p>
                          <i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>
                          jvg@edu.jekabpils.lv
                        </p>
                        <a href='https://maps.app.goo.gl/bWwMrfEG27fKztzw5' target='_blank' rel='noopener noreferrer'>
                        <p>
                          <i className="fas fa-location-pin" style={{paddingRight: '10px'}}></i>
                          Rūdolfa Blaumaņa iela 27, Jēkabpils, Jēkabpils novads, LV-5201
                        </p>
                        </a>
                      </div>
                      <div className="sb_footer_links_div">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2202.163410833272!2d25.852210977377847!3d56.49939527336969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e9b6f2b8acb97f%3A0xd2801fa9fe6bf3dc!2sR%C5%ABdolfa%20Blauma%C5%86a%20iela%2027%2C%20J%C4%93kabpils%2C%20LV-5201!5e0!3m2!1sen!2slv!4v1726409698746!5m2!1sen!2slv"
                            width="300"
                            height="225"
                            style={{ border: '0' }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                      </div>
                </div>
                <hr />
                <div className='sb_footer-below'>
                    <div className='sb_footer-copyright'>
                        <p>Visas tiesības aizsargātas © Limro Studios | {currentYear}</p>
                    </div>
                    <div className='sb_footer-below-links'>
                        {/* <a href="/gdprprivacypolicy"><div><p>GDPR</p></div></a>
                        <a href="/privacypolicy"><div><p>PPOl</p></div></a>
                        <a href="/termsofuse"><div><p>ToS</p></div></a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;