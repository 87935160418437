import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Container, Button, Typography, List, ListItem, ListItemText, Box, Divider, Alert, CircularProgress, Pagination, TextField } from '@mui/material';
import backendAddress from './BackendAddress';
import { useHtmlEditor } from './ShowHtmlContext';
import EditorComponent from './Editor';

const EditPages = ({ userData }) => {
    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState(null);
    const [contentJson, setContentJson] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const { showHtmlEditor, setShowHtmlEditor } = useHtmlEditor();

    useEffect(() => {
        fetchPages();
    }, [currentPage]); 

    const fetchPages = async () => {
        try {
            const response = await fetch(`${backendAddress}/api/allposts?page=${currentPage}&limit=10&search=${searchTerm}`, {
                headers: {
                    'session': Cookies.get('session'),
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch pages.');
            }

            const data = await response.json();
            setPages(data.lapas || []);
            setTotalPages(data.totalPages || 0);
            setLoading(false);
        } catch (error) {
            setError('Neizdevās dabūt lapas!');
            setLoading(false);
        }
    };

    const handleSelectPage = (page) => {
        setSelectedPage(page);
        setContentJson(page.content);
    };

    const handleSavePage = async () => {
        try {
            const response = await axios.post(
                `${backendAddress}/api/savepost`,
                {
                    pageId: selectedPage.id,
                    title: selectedPage.title,
                    timestamp: selectedPage.postedTimestamp,
                    content: contentJson,
                    executor: userData.username,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Author': userData.username,
                    },
                }
            );
            alert('Lapa veiksmīgi atjaunota!');
            window.location.reload();
        } catch (error) {
            alert('Neizdevās atjaunot lapu!');
        }
    };

    const handleDeletePage = async () => {
        try {
            const response = await axios.post(
                `${backendAddress}/api/deletepost`,
                {
                    pageId: selectedPage.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Author': userData.username,
                    },
                }
            );
            alert('Lapa tika veiksmīgi dzēsta!');
            window.location.reload();
        } catch (error) {
            alert('Neizdevās dzēst lapu!');
        }
    };

    return (
        <Container>
            <Box mt={14}>
                <Typography variant="h4" gutterBottom>
                    Rediģēt eksistējošās lapas
                </Typography>
                <Typography variant="body1" paragraph>
                    Izvēlaties lapu, kuru vēlaties rediģēt:
                </Typography>

                <TextField
                    label="Meklēt konkrētu lapu"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <Button variant="contained" color="primary" onClick={fetchPages} sx={{ mr: 2 }}>
                    Meklēt
                </Button>

                <Divider sx={{ my: 3 }} />

                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <List component="nav">
                        {pages.map((page) => (
                            <ListItem
                                key={page.id}
                                button
                                selected={selectedPage && selectedPage.id === page.id}
                                onClick={() => handleSelectPage(page)}
                            >
                                <ListItemText primary={page.title} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                />
            </Box>

            <Divider sx={{ my: 3 }} />

            {selectedPage && (
                <Box mt={3}>
                    <Typography variant="h5">Lapa - {selectedPage.title}</Typography>

                    <EditorComponent key={selectedPage.id} setContentJson={setContentJson} contentJson={contentJson} />

                    <Box mt={3}>
                        <Button variant="contained" color="primary" onClick={handleSavePage} sx={{ mr: 2 }} disabled={showHtmlEditor}>
                            Saglabāt lapu
                        </Button>
                        <Button variant="contained" color="error" onClick={handleDeletePage}>
                            Dzēst lapu
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default EditPages;