import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Image from '@tiptap/extension-image';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import ImageResize from 'tiptap-extension-resize-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Link from '@tiptap/extension-link';
import { ButtonNode } from '../../tiptap/CustomNodes';

import { generateJSON } from '@tiptap/html'

import { IconButton, Tooltip, ButtonGroup, Button, Input } from '@mui/material';
import { FormatQuote, Highlight as HighlightIcon } from '@mui/icons-material';
import { 
  FormatBold, FormatItalic, StrikethroughS, Code, FormatAlignLeft, 
  FormatAlignCenter, FormatAlignRight, FormatAlignJustify, Undo, Redo, 
  FormatColorText, InsertLink, LinkOff, FormatListBulleted, 
  FormatListNumbered, InsertPhoto, TableChart, Delete, HorizontalRule, 
  KeyboardReturn 
} from '@mui/icons-material';
import {
  AddCircle,
  RemoveCircle,
  DeleteForever,
  MergeType,
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import HtmlIcon from '@mui/icons-material/Html';

//components
import HTMLEditorComponent from './HTMLEditor';

//context
import { useHtmlEditor } from './ShowHtmlContext';
 
const MenuBar = ({ editor, setShowHtmlEditor, showHtmlEditor, setHtmlChanged }) => {
  const addImage = () => {
    const url = window.prompt('Image URL');
    if (!url) return;

    const width = window.prompt('Image Width (leave empty for default)');
    const height = window.prompt('Image Height (leave empty for default)');
  
    const defaultSize = { width: 300, height: 200 };
  
    editor
      .chain()
      .focus()
      .setImage({
        src: url,
        width: width || defaultSize.width,
        height: height || defaultSize.height,
      })
      .run();
  };

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  const addStandardButton = useCallback(() => {
    const link = window.prompt("Ievadiet link:");
    const btnName = window.prompt("Ievadiet pogas nosaukumu:");
    const openInNewTab = window.confirm("Vērt lapu jaunajā cilnē?");
    
    editor.chain().focus().insertContent({
      type: 'buttonNode',
      attrs: {
        link,
        openInNewTab,
        type: 'standard',
        text: btnName
      },
    }).run();
  }, [editor]);

  const addBigButton = useCallback(() => {
    const link = window.prompt("Ievadiet link:");
    const btnName = window.prompt("Ievadiet pogas nosaukumu:");
    const openInNewTab = window.confirm("Vērt lapu jaunajā cilnē?");
    
    editor.chain().focus().insertContent({
      type: 'buttonNode',
      attrs: {
        link,
        openInNewTab,
        type: 'big',
        text: btnName
      },
    }).run();
  }, [editor]);

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleUpdateButton = (attributes) => {
    editor.chain().focus().updateAttributes(hoveredButton, attributes).run();
  };

  if (!editor) {
    return null;
  }

  const showHtmlEditorFunc = () => {
    if(!showHtmlEditor){
      setShowHtmlEditor(true);
    } else {
      setShowHtmlEditor(false);
      setHtmlChanged(true);
    }
  };

  return (
    <div className="control-group">
      <ButtonGroup variant="outlined">
        <Tooltip title="Bold">
          <IconButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            color={editor.isActive('bold') ? 'primary' : 'default'}
          >
            <FormatBold />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italic">
          <IconButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            color={editor.isActive('italic') ? 'primary' : 'default'}
          >
            <FormatItalic />
          </IconButton>
        </Tooltip>
        <Tooltip title="Strikethrough">
          <IconButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            color={editor.isActive('strike') ? 'primary' : 'default'}
          >
            <StrikethroughS />
          </IconButton>
        </Tooltip>
        <Tooltip title="Code">
          <IconButton
            onClick={() => editor.chain().focus().toggleCode().run()}
            disabled={!editor.can().chain().focus().toggleCode().run()}
            color={editor.isActive('code') ? 'primary' : 'default'}
          >
            <Code />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear Formatting">
          <IconButton onClick={() => editor.chain().focus().unsetAllMarks().run()}>
            <Delete />
          </IconButton>
        </Tooltip>
        <Tooltip title="Undo">
          <IconButton
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().chain().focus().undo().run()}
          >
            <Undo />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo">
          <IconButton
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().chain().focus().redo().run()}
          >
            <Redo />
          </IconButton>
        </Tooltip>
        <Tooltip title="Set Color">
          <IconButton>
            <Input
              type="color"
              onChange={event => editor.chain().focus().setColor(event.target.value).run()}
              value={editor.getAttributes('textStyle').color || '#000000'}
              style={{ width: 40, padding: 0 }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Highlight">
          <IconButton onClick={() => editor.chain().focus().toggleHighlight().run()}>
            <HighlightIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Align Left">
          <IconButton
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            color={editor.isActive({ textAlign: 'left' }) ? 'primary' : 'default'}
          >
            <FormatAlignLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title="Align Center">
          <IconButton
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            color={editor.isActive({ textAlign: 'center' }) ? 'primary' : 'default'}
          >
            <FormatAlignCenter />
          </IconButton>
        </Tooltip>
        <Tooltip title="Align Right">
          <IconButton
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            color={editor.isActive({ textAlign: 'right' }) ? 'primary' : 'default'}
          >
            <FormatAlignRight />
          </IconButton>
        </Tooltip>
        <Tooltip title="Align Justify">
          <IconButton
            onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            color={editor.isActive({ textAlign: 'justify' }) ? 'primary' : 'default'}
          >
            <FormatAlignJustify />
          </IconButton>
        </Tooltip>
        <Tooltip title="Insert Image">
          <IconButton onClick={addImage}>
            <InsertPhoto />
          </IconButton>
        </Tooltip>
        <Tooltip title="Insert Link">
          <IconButton onClick={setLink} color={editor.isActive('link') ? 'primary' : 'default'}>
            <InsertLink />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove Link">
          <IconButton onClick={() => editor.chain().focus().unsetLink().run()} disabled={!editor.isActive('link')}>
            <LinkOff />
          </IconButton>
        </Tooltip>
        <Tooltip title="Bullet List">
          <IconButton onClick={() => editor.chain().focus().toggleBulletList().run()}>
            <FormatListBulleted />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ordered List">
          <IconButton onClick={() => editor.chain().focus().toggleOrderedList().run()}>
            <FormatListNumbered />
          </IconButton>
        </Tooltip>
        <Tooltip title="Set Paragraph">
        <IconButton onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') ? 'is-active' : ''}>
          P
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 1">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
          H1
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 2">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
          H2
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 3">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
          H3
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 4">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}>
        H4
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 5">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()} className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}>
        H5
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 6">
        <IconButton onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()} className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}>
        H6
        </IconButton>
      </Tooltip>
      <Tooltip title="Blockquote">
        <IconButton onClick={() => editor.chain().focus().toggleBlockquote().run()} className={editor.isActive('blockquote') ? 'is-active' : ''}>
          <FormatQuoteIcon/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Horizontal Rule">
        <IconButton onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <HorizontalRuleIcon/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Hard Break">
        <IconButton onClick={() => editor.chain().focus().setHardBreak().run()}>
          <SubdirectoryArrowLeftIcon/>
        </IconButton>
      </Tooltip>
      </ButtonGroup>
      <Tooltip title="HTML Editor">
        <IconButton onClick={() => showHtmlEditorFunc()}>
          <HtmlIcon/>
        </IconButton>
      </Tooltip>
      <ButtonGroup variant="outlined">
      <Tooltip title="Insert Table">
        <IconButton onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}>
          <TableChart />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Column Before">
        <IconButton onClick={() => editor.chain().focus().addColumnBefore().run()} disabled={!editor.can().addColumnBefore()}>
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Column After">
        <IconButton onClick={() => editor.chain().focus().addColumnAfter().run()} disabled={!editor.can().addColumnAfter()}>
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Column">
        <IconButton onClick={() => editor.chain().focus().deleteColumn().run()} disabled={!editor.can().deleteColumn()}>
          <RemoveCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Row Before">
        <IconButton onClick={() => editor.chain().focus().addRowBefore().run()} disabled={!editor.can().addRowBefore()}>
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Row After">
        <IconButton onClick={() => editor.chain().focus().addRowAfter().run()} disabled={!editor.can().addRowAfter()}>
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Row">
        <IconButton onClick={() => editor.chain().focus().deleteRow().run()} disabled={!editor.can().deleteRow()}>
          <RemoveCircle />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Table">
        <IconButton onClick={() => editor.chain().focus().deleteTable().run()} disabled={!editor.can().deleteTable()}>
          <DeleteForever />
        </IconButton>
      </Tooltip>
      <Tooltip title="Merge Cells">
        <IconButton onClick={() => editor.chain().focus().mergeCells().run()} disabled={!editor.can().mergeCells()}>
          <MergeType />
        </IconButton>
      </Tooltip>
      <Tooltip title="Split Cell">
        <IconButton onClick={() => editor.chain().focus().splitCell().run()} disabled={!editor.can().splitCell()}>
          <SplitscreenIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Fix Tables">
        <IconButton onClick={() => editor.chain().focus().fixTables().run()} disabled={!editor.can().fixTables()}>
          <CheckBoxOutlineBlank />
        </IconButton>
      </Tooltip>
      <Tooltip title="Toggle Header Column">
        <IconButton onClick={() => editor.chain().focus().toggleHeaderColumn().run()} disabled={!editor.can().toggleHeaderColumn()}>
          <CheckBox />
        </IconButton>
      </Tooltip>
      <Tooltip title="Toggle Header Row">
        <IconButton onClick={() => editor.chain().focus().toggleHeaderRow().run()} disabled={!editor.can().toggleHeaderRow()}>
          <CheckBox />
        </IconButton>
      </Tooltip>
      <Tooltip title="Toggle Header Cell">
        <IconButton onClick={() => editor.chain().focus().toggleHeaderCell().run()} disabled={!editor.can().toggleHeaderCell()}>
          <CheckBox />
        </IconButton>
      </Tooltip>
      <Tooltip title="Go to Next Cell">
        <IconButton onClick={() => editor.chain().focus().goToNextCell().run()} disabled={!editor.can().goToNextCell()}>
          <FormatAlignRight />
        </IconButton>
      </Tooltip>
      <Tooltip title="Go to Previous Cell">
        <IconButton onClick={() => editor.chain().focus().goToPreviousCell().run()} disabled={!editor.can().goToPreviousCell()}>
          <FormatAlignLeft />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
    </div>
  );
};

const EditorComponent = ({ setContentJson, contentJson }) => {
  const CustomTableCell = TableCell.extend({
      addAttributes() {
          return {
              ...this.parent?.(),
              backgroundColor: {
                  default: null,
                  parseHTML: element => element.getAttribute('data-background-color'),
                  renderHTML: attributes => ({
                      'data-background-color': attributes.backgroundColor,
                      style: `background-color: ${attributes.backgroundColor}`,
                  }),
              },
          };
      },
  });

  const CustomTextStyle = TextStyle.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        backgroundColor: {
          default: null,
          parseHTML: element => element.style.backgroundColor,
          renderHTML: attributes => {
            if (!attributes.backgroundColor) return {};
            return { style: `background-color: ${attributes.backgroundColor}` };
          },
        },
        fontSize: {
          default: null,
          parseHTML: element => element.style.fontSize,
          renderHTML: attributes => {
            if (!attributes.fontSize) return {};
            return { style: `font-size: ${attributes.fontSize}` };
          },
        },
        textAlign: {
          default: null,
          parseHTML: element => element.style.textAlign,
          renderHTML: attributes => {
            if (!attributes.textAlign) return {};
            return { style: `text-align: ${attributes.textAlign}` };
          },
        },
        lineHeight: {
          default: null,
          parseHTML: element => element.style.lineHeight,
          renderHTML: attributes => {
            if (!attributes.lineHeight) return {};
            return { style: `line-height: ${attributes.lineHeight}` };
          },
        },
        fontWeight: {
          default: null,
          parseHTML: element => element.style.fontWeight,
          renderHTML: attributes => {
            if (!attributes.fontWeight) return {};
            return { style: `font-weight: ${attributes.fontWeight}` };
          },
        },
        fontStyle: {
          default: null,
          parseHTML: element => element.style.fontStyle,
          renderHTML: attributes => {
            if (!attributes.fontStyle) return {};
            return { style: `font-style: ${attributes.fontStyle}` };
          },
        },
        textDecoration: {
          default: null,
          parseHTML: element => element.style.textDecoration,
          renderHTML: attributes => {
            if (!attributes.textDecoration) return {};
            return { style: `text-decoration: ${attributes.textDecoration}` };
          },
        },
        color: {
          default: null,
          parseHTML: element => element.style.color,
          renderHTML: attributes => {
            if (!attributes.color) return {};
            return { style: `color: ${attributes.color}` };
          },
        },
        letterSpacing: {
          default: null,
          parseHTML: element => element.style.letterSpacing,
          renderHTML: attributes => {
            if (!attributes.letterSpacing) return {};
            return { style: `letter-spacing: ${attributes.letterSpacing}` };
          },
        },
        textTransform: {
          default: null,
          parseHTML: element => element.style.textTransform,
          renderHTML: attributes => {
            if (!attributes.textTransform) return {};
            return { style: `text-transform: ${attributes.textTransform}` };
          },
        },
        // Add more styles as needed
      };
    },
  });

  const extensions = useMemo(() => [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
          bulletList: {
              keepMarks: true,
              keepAttributes: false,
          },
          orderedList: {
              keepMarks: true,
              keepAttributes: false,
          },
          bold: true,
      }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Highlight,
      Document,
      Paragraph,
      Text,
      Image,
      Dropcursor,
      ImageResize,
      Table.configure({ resizable: true }),
      TableRow,
      TableHeader,
      CustomTableCell,
      CustomTextStyle,
      Link.configure({
          openOnClick: false,
          autolink: true,
          defaultProtocol: 'https',
      }),
      ButtonNode,
  ], []);

  const editor = useEditor({
      extensions: extensions,
      content: contentJson,
      onUpdate: ({ editor }) => {
          const jsonContent = editor.getJSON();
          console.log("Updated JSON Content:", jsonContent);
          setContentJson(jsonContent);
      },
  });

  const { showHtmlEditor, setShowHtmlEditor } = useHtmlEditor();
  const [htmlContent, setHTMLContent] = useState('');
  const [displayHtml, setDisplayHtml] = useState(false);
  const [htmlChanged, setHtmlChanged] = useState(false);

  useEffect(() => {
      console.log("Show HTML Editor:", showHtmlEditor);
      if (showHtmlEditor) {
          if (htmlContent === '') {
              const currentHtmlContent = editor.getHTML();
              console.log("Current HTML Content:", currentHtmlContent);
              setHTMLContent(currentHtmlContent);
          }
          setDisplayHtml(true);
      } else {
          if(htmlChanged){
            try {
                const jsonContent = generateJSON(htmlContent, extensions);
                console.log("Generated JSON Content from HTML:", jsonContent);

                if (jsonContent != null && jsonContent !== '') {
                    console.log("Setting Content JSON:", jsonContent);
                    setContentJson(jsonContent);
                    editor.commands.setContent(jsonContent);
                } else {
                    console.warn("Generated JSON is invalid or empty.");
                }
            } catch (error) {
                console.error("Error generating JSON from HTML:", error);
            }
          }
          setDisplayHtml(false);
      }
  }, [showHtmlEditor, htmlContent, editor, extensions]);

  return (
      <div>
          <div className='menu-bar'>
              <MenuBar editor={editor} setShowHtmlEditor={setShowHtmlEditor} showHtmlEditor={showHtmlEditor} setHtmlChanged={setHtmlChanged}/>
          </div>
          {displayHtml ? (
              <HTMLEditorComponent htmlCode={htmlContent} setHtmlCode={setHTMLContent} displayHtml={displayHtml} />
          ) : (
              <EditorContent editor={editor} />
          )}
      </div>
  );
};

export default EditorComponent;