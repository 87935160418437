import React, { useState } from 'react';
import Cookies from 'js-cookie';

import backendAddress from './BackendAddress';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: '100vh',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    backgroundRepeat: 'no-repeat',
  },
}));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#fff',
    },
  },
});

const PanelLogin = ({ loggedIn, setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [lastIncorrect, setLastIncorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')

  const handleLogin = (e) => {
    e.preventDefault();

    fetch(`${backendAddress}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        username,
        password,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setLastIncorrect(true);
          setErrorMsg('Nepareizs konta vārds vai parole!');
          throw new Error('Login failed');
        }
      })
      .then((data) => {
        const token = data.token;
        Cookies.set('session', token, { expires: 1 / 4 });
        setLoggedIn(true);
        window.location.reload();
      })
      .catch((error) => {
        setLastIncorrect(true);
        setErrorMsg('Nepareizs konta vārds vai parole!');
        console.error('Error during login:', error);
      });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="center">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Autorizēšanās
          </Typography>
          <Typography component="p" variant='subtitle1'>
            Lai turpinātu, ievadiet konta vārdu un paroli.
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="username">Konta vārds</FormLabel>
              <TextField
                error={lastIncorrect}
                id="username"
                type="username"
                name="username"
                placeholder="Konta vārds"
                autoComplete="username"
                autoFocus
                required
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                color={lastIncorrect ? 'error' : 'primary'}
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Parole</FormLabel>
              </Box>
              <TextField
                error={lastIncorrect}
                helperText={errorMsg}
                name="password"
                placeholder="•••••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                color={lastIncorrect ? 'error' : 'primary'}
              />
            </FormControl>
            <Button type="submit" color='primary' fullWidth variant="contained" onClick={handleLogin}>
                Ieiet
            </Button>
          </Box>
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
};

export default PanelLogin;