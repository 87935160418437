import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import backendAddress from '../panel_data/components/BackendAddress';
import TiptapRenderer from '../components/PageRenderer';
import LoadingAnimation from '../components/LoadingAnimation';
import ImageControllerLighthouse from '../components/ImageControllerLighthouse';

const RenderPage = ({ NotificationManager }) => {
    const [contentJson, setContentJson] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(`${backendAddress}/api/getpage/${id}`);
                //console.log('Fetched content:', response.data);
                setContentJson(response.data);

                setImages(extractImages(response.data.content));
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setNotFound(true);
                } else {
                    console.error('Error fetching page content:', error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchContent();
    }, [id]);

    useEffect(() => {
        if (contentJson) {
            const numericId = parseInt(id, 10);
            if (Number.isInteger(numericId)) {
                const newLink = `${window.location.origin}/lapa/${contentJson.link}`;
                window.history.pushState({}, '', newLink);
            }
        }
    }, [contentJson, id]);

    if (notFound) {
        return <Navigate to="/navatrasta" />;
    }

    const CopyLinkToClipboard = () => {
        const linkToCopy = `${window.location.origin}/ej-uz-lapu/${contentJson.id}`;
        navigator.clipboard.writeText(linkToCopy)
            .then(() => {
                console.log('Link copied to clipboard:', linkToCopy);
                NotificationManager.success('Saite ir veiksmīgi nokopēta!', 'Īsā saite');
            })
            .catch(err => {
                console.error('Failed to copy the link: ', err);
                NotificationManager.error('Neizdevās nokopēt saiti!', 'Īsā saite');
            });
    };

    const extractImages = (content) => {
        let imageLinks = [];

        const findImages = (node) => {
            if (Array.isArray(node)) {
                node.forEach(findImages);
            } else if (node && typeof node === 'object') {
                if (node.type === 'image' && node.attrs && node.attrs.src) {
                    imageLinks.push(node.attrs.src);
                }
                Object.values(node).forEach(findImages);
            }
        };

        findImages(content);
        return imageLinks;
    };

    const handleImageClick = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className='zd_container_2' style={{ marginTop: '30px', marginBottom: '30px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)' }}>
                <Helmet>
                    <title>{isLoading ? '...' : contentJson.title} | Jēkabpils Valsts ģimnāzija</title>
                </Helmet>
                {isLoading ? (
                    <LoadingAnimation />
                ) : (
                    <div>
                        {/* <h1 className='page_header'>{contentJson.title}</h1> */}
                        <hr />
                        <TiptapRenderer 
                            contentJson={contentJson.content} 
                            onImageClick={handleImageClick} 
                        />
                        <div style={{ marginTop: '50px', borderTop: '1px solid #ddd', paddingTop: '30px' }}>
                            <p style={{ margin: '0 0 5px', fontSize: '16px' }}>
                                <a 
                                    onClick={CopyLinkToClipboard} 
                                    style={{ cursor: 'pointer', color: '#6e0b0d', textDecoration: 'underline' }}>
                                    Īsā saite
                                </a>
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <ImageControllerLighthouse
                images={images}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
        </div>
    );
};

export default RenderPage;