import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider'; // Import Divider

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArticleIcon from '@mui/icons-material/Article';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EventIcon from '@mui/icons-material/Event';
import CollectionsIcon from '@mui/icons-material/Collections';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function MenuContent({ userData, setSelected, selected }) {
  const perm3Elements = [
    { text: 'Izveidot jaunu lietotāju', icon: <PersonAddIcon />, id: 1 },
    { text: 'Izmainīt paroli lietotājam', icon: <PeopleRoundedIcon />, id: 2 },
    { text: 'Dzēst lietotāju', icon: <PersonRemoveIcon />, id: 3 },
    { text: 'Sessiju menedžeris', icon: <ReceiptIcon />, id: 4 },
  ];

  const perm2Elements = [
    { text: 'Redaktēt fast access elementus', icon: <ArticleIcon />, id: 5 },
    { text: 'Redaktēt navbar elementus', icon: <ArticleIcon />, id: 6 },
    { text: 'Redaktēt footer elementus', icon: <ArticleIcon />, id: 7 },
    { text: 'Augšupielādēt dzimšanas dienas', icon: <CloudUploadIcon />, id: 8 },
  ];

  const perm1Elements = [
    { text: 'Pievienot jaunu lapu', icon: <NoteAddIcon />, id: 9 },
    { text: 'Pārvāldīt lapas', icon: <NoteAltIcon />, id: 10 },
    { text: 'Izveidot jaunu rakstu', icon: <AssignmentRoundedIcon />, id: 11 },
    { text: 'Pārvaldīt rakstus', icon: <PostAddIcon />, id: 12 },
    { text: 'Pārvaldīt pasākumus', icon: <EventIcon />, id: 13 },
    { text: 'Pārvaldīt galerijas', icon: <CollectionsIcon />, id: 14 },
    { text: 'Pārvaldīt failus', icon: <CloudUploadIcon />, id: 15 },
  ];

  const commonElements = [{ text: 'Sākums', icon: <HomeRoundedIcon />, id: 0 }];

  const getPermissionElements = () => {
    if (userData.perm_level === 3) {
      return [...commonElements, ...perm3Elements];
    } else if (userData.perm_level === 2) {
      return [...commonElements, ...perm2Elements, ...perm1Elements];
    } else if (userData.perm_level === 1) {
      return [...commonElements, ...perm1Elements];
    } else {
      return [...commonElements, { text: 'Tiesību kļūda', icon: null }];
    }
  };

  const perm = getPermissionElements();

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {/* Always render the commonElements */}
        {commonElements.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton selected={selected === index} onClick={() => setSelected(index)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}

        {/* Add divider after Sākums */}
        <Divider sx={{ my: 1 }} />

        {/* Render permission-based elements with dividers between permission groups */}
        {userData.perm_level === 3 && (
          <>
            {perm3Elements.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton selected={selected === index + 1} onClick={() => setSelected(index + 1)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ my: 1 }} />
          </>
        )}

        {userData.perm_level >= 2 && (
          <>
            {perm2Elements.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton selected={selected === index + 1 + perm3Elements.length} onClick={() => setSelected(index + 1 + perm3Elements.length)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ my: 1 }} />
          </>
        )}

        {userData.perm_level >= 1 && (
          <>
            {perm1Elements.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton selected={selected === index + 1 + perm3Elements.length + perm2Elements.length} onClick={() => setSelected(index + 1 + perm3Elements.length + perm2Elements.length)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Stack>
  );
}