import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

// MUI imports
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import PanelLogin from '../components/Login';
import PanelNavbar from '../components/PanelNavbar';
import SideMenu from '../components/SideMenu';
import MainGrid from '../components/MainGrid';
import backendAddress from '../components/BackendAddress';

// CSS
import '../css/p_main.css';

const Panele = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [darbiba, setDarbiba] = useState('default');
    const [loading, setLoading] = useState(false);

    //for displaying
    const [selected, setSelected] = React.useState(0);

    const handleClick = (action) => {
        setDarbiba(action);
    };

    const fetchAllUsers = async () => {
        const session_id = Cookies.get('session');
        try {
            const response = await fetch(`${backendAddress}/api/getallusers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session_id': session_id
                }
            });
    
            if (!response.ok) {
                throw new Error(`Network response was not ok. Status: ${response.status}`);
            }
    
            const users = await response.json();
            setAllUsers(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (darbiba === 'dzest_lietotaju') {
            fetchAllUsers();
        } else if (darbiba === 'izmainit_paroli') {
            fetchAllUsers();
        }
    }, [darbiba]);

    useEffect(() => {
        const sessionToken = Cookies.get('session');

        if (sessionToken) {
            setLoading(true);
            fetch(`${backendAddress}/api/getsessiondate`, {
                method: 'GET',
                headers: {
                    'session': sessionToken
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.timestamp) {
                    const sessionTimestamp = new Date(data.timestamp).getTime();
                    const currentTime = new Date().getTime();
                    const sessionAge = (currentTime - sessionTimestamp) / (1000 * 60 * 60);

                    if (sessionAge < 6) {
                        fetch(`${backendAddress}/api/getuserdata`, {
                            method: 'POST',
                            headers: {
                                'session_id': sessionToken,
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(response => response.json())
                        .then(userData => {
                            setUserData(userData);
                            setLoggedIn(true);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error('Error fetching user data:', error);
                            setLoading(false);
                        });
                    } else {
                        console.log('Session expired');
                        Cookies.remove('session');
                        setLoading(false);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching session date:', error);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Helmet>
                <title>Administrēšanas panele | Jēkabpils Valsts ģimnāzija</title>
            </Helmet>
            
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : loggedIn ? (
                <Box sx={{ display: 'flex' }}>
                    <SideMenu userData={userData} setSelected={setSelected} selected={selected} setLoggedIn={setLoggedIn}/>
                    <PanelNavbar userData={userData} setLoggedIn={setLoggedIn} selected={selected} setSelected={setSelected}/>
                    
                    <Box
                      component="main"
                      sx={(theme) => ({
                        flexGrow: 1,
                        backgroundColor: theme.vars
                          ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                          : alpha(theme.palette.background.default, 1),
                        overflow: 'auto',
                      })}
                    >
                      <Stack
                        spacing={2}
                        sx={{
                          alignItems: 'center',
                          mx: 3,
                          pb: 5,
                          mt: { xs: 8, md: 0 },
                        }}
                      >
                        <MainGrid selected={selected} userData={userData}/>
                      </Stack>
                    </Box>
                </Box>
            ) : (
                <PanelLogin loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            )}
        </ThemeProvider>
    );
};

export default Panele;