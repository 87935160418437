import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import backendAddress from './BackendAddress';

import { Container, Typography, Select, MenuItem, Button, FormControl, InputLabel, Box, CircularProgress, Alert, List, ListItem, ListItemText } from '@mui/material';

const DeleteUser = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [disabledMainBtn, setDisabledMainBtn] = useState(true);

  useEffect(() => {
    if(selectedUserId!==''){
      console.log(selectedUserId);
      if(selectedUserId!=='1'){
        setDisabledMainBtn(false);
      } else {
        setDisabledMainBtn(true);
      }
    } else {
      setDisabledMainBtn(true);
    }
  }, [selectedUserId])

  const fetchAllUsers = async () => {
    const session_id = Cookies.get('session');
    try {
      const response = await fetch(`${backendAddress}/api/getallusers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'session_id': session_id
        }
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const users = await response.json();
      setAllUsers(users);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleDeleteUser = async () => {
    const sessionToken = Cookies.get('session');
    
    if (!selectedUserId) {
      alert('Please select a user to delete.');
      return;
    }

    if (selectedUserId === '1') {
      alert('Deleting the administrator account is not allowed!');
      return;
    }

    const isConfirmed = window.confirm(`Are you sure you want to delete this user?`);

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${backendAddress}/api/deleteuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'session_id': sessionToken
        },
        body: JSON.stringify({ userId: selectedUserId })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const result = await response.json();
      setSuccessMessage(result.message);
      setError('');
      fetchAllUsers(); // Refresh the list of users after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete the user.');
      setSuccessMessage('');
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Dzēst JVĢ-ADMIN lietotāju
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            Pieejamie lietotāji:
          </Typography>

          <List>
            {allUsers.map(user => (
              <ListItem key={user.id}>
                <ListItemText
                  primary={`${user.username} (ID: ${user.id})`}
                  secondary={`E-pasts: ${user.email} | Administrēšanas līmenis: ${user.perm_level}`}
                />
              </ListItem>
            ))}
          </List>

          <FormControl fullWidth margin="normal">
            <InputLabel required>Izvēlaties lietotāju</InputLabel>
            <Select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
            >
              <MenuItem value="">
                <em>Izvēlaties lietotāju</em>
              </MenuItem>
              {allUsers.map(user => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username} (ID: {user.id})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box mt={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteUser}
              disabled={disabledMainBtn}
            >
              Dzēst lietotāju
            </Button>
          </Box>

          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </>
      )}
    </Container>
  );
};

export default DeleteUser;