import React, { useEffect, useState } from 'react';
import backendAddress from './BackendAddress';
import {
    Button, TextField, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Typography, Box
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';

const EditFooterElements = () => {
    const [footerElements, setFooterElements] = useState([]);
    const currentYear = new Date().getFullYear();
    const [editingHeaderIndex, setEditingHeaderIndex] = useState(null);

    useEffect(() => {
        fetch(`${backendAddress}/api/getfooterelements`)
            .then(response => response.json())
            .then(data => setFooterElements(data))
            .catch(error => console.error('Error fetching footer elements:', error));
    }, []);

    const handleChange = (footerIndex, elementIndex, key, value) => {
        const updatedElements = [...footerElements];
        const footerElement = updatedElements[footerIndex];
    
        if (key === 'id') {
            const newId = parseInt(value);
            const conflictingIndex = footerElement.elements.findIndex(
                (el, idx) => el.id === newId && idx !== elementIndex
            );
    
            if (conflictingIndex !== -1) {
                const conflictingElement = footerElement.elements[conflictingIndex];
                const tempId = conflictingElement.id;
    
                conflictingElement.id = footerElement.elements[elementIndex].id;
                footerElement.elements[elementIndex].id = tempId;
            } else {
                footerElement.elements[elementIndex].id = newId;
            }
        } else {
            footerElement.elements[elementIndex][key] = value;
        }
    
        footerElement.elements.sort((a, b) => a.id - b.id);
        setFooterElements(updatedElements);
    };

    const addNewElement = (footerIndex) => {
        const updatedElements = [...footerElements];
        const newId = updatedElements[footerIndex].elements.length > 0 
            ? Math.max(...updatedElements[footerIndex].elements.map(el => el.id)) + 1 
            : 0;

        const newElement = {
            id: newId,
            link: "https://jususaite.com",
            name: "Jauns Elements",
            newTab: false
        };

        updatedElements[footerIndex].elements.push(newElement);
        setFooterElements(updatedElements);
    };

    const addNewHeader = () => {
        const newId = footerElements.length > 0 
            ? Math.max(...footerElements.map(el => el.id)) + 1 
            : 0;

        const newHeader = {
            id: newId,
            headerName: "Jauns Header",
            elements: []
        };

        setFooterElements([...footerElements, newHeader]);
    };

    const moveHeader = (fromIndex, toIndex) => {
        const updatedElements = [...footerElements];
        const [movedHeader] = updatedElements.splice(fromIndex, 1);
        updatedElements.splice(toIndex, 0, movedHeader);
        setFooterElements(updatedElements);
    };

    const saveChanges = () => {
        fetch(`${backendAddress}/api/savefooterelements`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ updatedElements: footerElements })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log(data.message);
            alert('Footer Elements: izmaiņas veiksmīgi saglabātas!');
        })
        .catch(error => {
            console.error('Error saving footer elements:', error);
            alert('Footer Elements: Neizdevās saglabāt izmaiņas!');
        })
        .finally(() => {
            window.location.reload();
        });
    };

    const handleDelete = (footerIndex, elementIndex) => {
        const updatedElements = [...footerElements];
        updatedElements[footerIndex].elements.splice(elementIndex, 1);
        const reIndexedElements = updatedElements[footerIndex].elements.map((element, newIndex) => ({
            ...element,
            id: newIndex
        }));
        updatedElements[footerIndex].elements = reIndexedElements;
        setFooterElements(updatedElements);
    };

    const handleHeaderChange = (footerIndex, value) => {
        const updatedElements = [...footerElements];
        updatedElements[footerIndex].headerName = value;
        setFooterElements(updatedElements);
    };

    const handleDeleteHeader = (footerIndex) => {
        if (window.confirm("Vai jūs tiešām vēlaties izdzēst šo sadaļu?")) {
            const updatedElements = footerElements.filter((_, index) => index !== footerIndex);
            setFooterElements(updatedElements);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>Mainīt footer elementus</Typography>
            
            <Button variant="contained" onClick={addNewHeader} sx={{ mb: 3 }}>Pievienot jaunu sadaļu</Button>
            <hr />
            {footerElements.map((footerElement, footerIndex) => (
                <div key={footerIndex}>
                    <Typography variant="h6">
                        {editingHeaderIndex === footerIndex ? (
                            <TextField
                                value={footerElement.headerName}
                                onChange={(e) => handleHeaderChange(footerIndex, e.target.value)}
                                onBlur={() => setEditingHeaderIndex(null)}
                                autoFocus
                            />
                        ) : (
                            <>
                                {footerElement.headerName}
                                <IconButton onClick={() => moveHeader(footerIndex, footerIndex - 1)} disabled={footerIndex === 0}>
                                    <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton onClick={() => moveHeader(footerIndex, footerIndex + 1)} disabled={footerIndex === footerElements.length - 1}>
                                    <ArrowDownwardIcon />
                                </IconButton>
                                <IconButton onClick={() => setEditingHeaderIndex(footerIndex)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteHeader(footerIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        )}
                    </Typography>
                    <Button variant="outlined" onClick={() => addNewElement(footerIndex)} sx={{ mb: 3 }}>
                        Pievienot jaunu elementu
                    </Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Saite</TableCell>
                                <TableCell>Vārds</TableCell>
                                <TableCell>Jaunā cilnē?</TableCell>
                                <TableCell>Dzēst?</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {footerElement.elements.map((element, elementIndex) => (
                                <TableRow key={elementIndex}>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={element.id}
                                            onChange={(e) => handleChange(footerIndex, elementIndex, 'id', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={element.link}
                                            onChange={(e) => handleChange(footerIndex, elementIndex, 'link', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={element.name}
                                            onChange={(e) => handleChange(footerIndex, elementIndex, 'name', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            checked={element.newTab}
                                            onChange={(e) => handleChange(footerIndex, elementIndex, 'newTab', e.target.checked)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDelete(footerIndex, elementIndex)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <hr />
                </div>
            ))}

            <hr></hr>
            <h2>Priekšskats:</h2>

<div className="footer2">
<div className="sb_footer section_padding">
<div className='sb_footer-links'>
        {footerElements.map(element => (
            <div key={element.id}>
                <div className='sb_footer-links-div'>
                <h4>{element.headerName}</h4>
                {element.elements.map(subelement => (
                    <a
                        key={subelement.id}
                        href={subelement.link}
                        target={subelement.newTab ? "_blank" : "_self"}
                        rel={subelement.newTab ? "noopener noreferrer" : undefined}
                    >
                        <p>{subelement.name}</p>
                    </a>
                ))}
                </div>
            </div>
        ))}
      <div className='sb_footer-links-div'>
        <h4>Kontakti</h4>
        <p>
          <i className="fas fa-phone" style={{paddingRight: '10px'}}></i>
          +371 65237551
        </p>
        <p>
          <i className="fas fa-envelope" style={{paddingRight: '10px'}}></i>
          jvg@edu.jekabpils.lv
        </p>
        <a href='https://maps.app.goo.gl/bWwMrfEG27fKztzw5' target='_blank' rel='noopener noreferrer'>
        <p>
          <i className="fas fa-location-pin" style={{paddingRight: '10px'}}></i>
          Rūdolfa Blaumaņa iela 27, Jēkabpils, Jēkabpils novads, LV-5201
        </p>
        </a>
      </div>
      <div className="sb_footer_links_div">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2202.163410833272!2d25.852210977377847!3d56.49939527336969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e9b6f2b8acb97f%3A0xd2801fa9fe6bf3dc!2sR%C5%ABdolfa%20Blauma%C5%86a%20iela%2027%2C%20J%C4%93kabpils%2C%20LV-5201!5e0!3m2!1sen!2slv!4v1726409698746!5m2!1sen!2slv"
            width="300"
            height="225"
            style={{ border: '0' }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
</div>
<hr />
<div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p>Autortiesības © Limro Studios | {currentYear}</p>
    </div>
    <div className='sb_footer-below-links'>
        {/* <a href="/gdprprivacypolicy"><div><p>GDPR</p></div></a>
        <a href="/privacypolicy"><div><p>PPOl</p></div></a>
        <a href="/termsofuse"><div><p>ToS</p></div></a> */}
    </div>
</div>
</div>
</div>
            <hr></hr>
            <Button variant="contained" color="primary" onClick={saveChanges} sx={{ mt: 3 }}>Saglabāt izmaiņas</Button>
        </Box>
    );
};

export default EditFooterElements;