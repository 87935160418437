import React from 'react';
import { Helmet } from 'react-helmet';

const NavAtrasta = () => {
    return (
        <div>
            <Helmet>
                <title>404 | Jēkabpils Valsts ģimnāzija</title>
            </Helmet>
			
			<div className='container-main'>
                <div className='gl_sad2' data-parallax="true">
                    <div className='container'>
				        <h1 className='title h1'>Lapa nav atrasta! 404!</h1>
                        <p>Lūdzu, pārbaudiet adresi vai izmantojiet izvēlni, lai atrastu vajadzīgo informāciju.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavAtrasta;