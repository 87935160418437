// src/Sadala.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import backendAddress from '../panel_data/components/BackendAddress';

const Sadala = () => {
    const { sadala } = useParams(); // Get the :sadala parameter
    const [navbarElements, setNavbarElements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNavbarElements = async () => {
            try {
                const response = await fetch(`${backendAddress}/api/getnavbarelements`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNavbarElements(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchNavbarElements();
    }, []);

    if (loading) return <p className="loading">Loading elements...</p>;
    if (error) return <p className="error">Error: {error.message}</p>;

    // Find the specific navbar element based on the sadala parameter
    const selectedElement = navbarElements.find((element) => element.name === sadala);

    return (
        <div className="sadala-container">
            <h1>{selectedElement ? selectedElement.name : 'Not Found'}</h1>
            <div className="buttons-container">
                {selectedElement ? (
                    <>
                        {selectedElement.dropdownElements.length > 0 ? (
                            <div className="dropdown-buttons">
                                {selectedElement.dropdownElements.map((dropdown) => (
                                    <a 
                                        key={dropdown.id} 
                                        href={dropdown.link} 
                                        target={dropdown.newTab ? "_blank" : "_self"}
                                        rel={dropdown.newTab ? "noopener noreferrer" : undefined}
                                        className="big-button"
                                    >
                                        {dropdown.name}
                                    </a>
                                ))}
                            </div>
                        ) : (
                            <a 
                                href={selectedElement.link} 
                                target={selectedElement.newTab ? "_blank" : "_self"}
                                rel={selectedElement.newTab ? "noopener noreferrer" : undefined}
                                className="big-button"
                            >
                                {selectedElement.name}
                            </a>
                        )}
                    </>
                ) : (
                    <p className="no-element">Šis elements nav atrasts.</p>
                )}
            </div>
        </div>
    );
};

export default Sadala;