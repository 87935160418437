import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Image from '@tiptap/extension-image';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import ImageResize from 'tiptap-extension-resize-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Link from '@tiptap/extension-link';
import { ButtonNode } from '../tiptap/CustomNodes';

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

const TiptapRenderer = ({ contentJson, onImageClick }) => {
  const editor = useEditor({
    immediatelyRender: false,
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        bold: true,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
      Document,
      Paragraph,
      Text,
      Image,
      Dropcursor,
      ImageResize,
      Table.configure({
        resizable: false,
      }),
      TableRow,
      TableHeader,
      CustomTableCell,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https',
      }),
      ButtonNode,
    ],
    content: contentJson,
    editable: false,
  });

  useEffect(() => {
    // Attach click handler to images after editor content has rendered
    if (editor) {
      const imgElements = editor.view.dom.querySelectorAll('img');
      imgElements.forEach((img, index) => {
        img.style.cursor = 'pointer'; // Change cursor for better UX
        img.onclick = () => onImageClick(index); // Attach click handler
      });
    }
  }, [editor, onImageClick]); // Ensure effect runs when editor is initialized or onImageClick changes

  return (
    <div className="editor-container">
      <EditorContent editor={editor} />
    </div>
  );
};

export default TiptapRenderer;