import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/lv';
import { Modal, Box, Typography, Button } from '@mui/material';
import backendAddress from '../panel_data/components/BackendAddress';
import { Helmet } from 'react-helmet';
import { faCalendar, faCake } from '@fortawesome/free-solid-svg-icons';

moment.locale('lv');
const localizer = momentLocalizer(moment);

const messages = {
  today: 'Šodien',
  previous: 'Iepriekšējais',
  next: 'Nākamais',
  month: 'Mēnesis',
  week: 'Nedēļa',
  day: 'Diena',
  agenda: 'Pasākumi',
  date: 'Datums',
  time: 'Laiks',
  event: 'Notikums',
  noEventsInRange: 'Nav notikumu',
  showMore: (total) => `+ Vēl ${total}`
};

const Kalendars = () => {
  const [events, setEvents] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [academicYear, setAcademicYear] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const rigaOffset = 3 * 60;
    const now = new Date();
    const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    const rigaTime = new Date(utcNow.getTime() + (rigaOffset * 60000));

    const month = rigaTime.getMonth() + 1;
    const year = rigaTime.getFullYear();
    const academicYear = (month >= 9) ? year : year - 1;
    setAcademicYear(academicYear);
  }, []);

  useEffect(() => {
    console.log(selectedEvent);
  }, [selectedEvent])

  useEffect(() => {
    if (academicYear) {
      // Fetch events
      fetch(`${backendAddress}/api/getEvents/${academicYear}`)
        .then(response => response.json())
        .then(data => {
          const transformedEvents = data.map(event => {
            const start = new Date(`${event.date}T${event.time || '00:00'}`);
            let end;
            if(event.endDate){
              end = new Date(`${event.endDate}T${event.endTime || '23:59:59'}`);
            } else {
              end = new Date(`${event.date}T${event.endTime || '23:59:59'}`);
            }

            return {
              id: `${event.id.toString()}`,
              title: `[P] ${event.name.toString()}`,
              start,
              end,
              location: event.location,
              additionalInfo: event.additionalInfo,
              people: event.people
            };
          });
          setEvents(transformedEvents);
        })
        .catch(error => console.error('Error fetching events:', error));

      fetch(`${backendAddress}/api/birthdays`)
        .then(response => response.json())
        .then(data => {
          const transformedBirthdays = data.flatMap(birthday => 
            birthday.names.map(name => {
              const dateParts = birthday.date.split('.');
              const start = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00`);
              return {
                id: `b-${name.toString()}`,
                title: `[DZ] ${name}`,
                start,
                end: start,
              };
            })
          );
          setBirthdays(transformedBirthdays);
        })
        .catch(error => console.error('Error fetching birthdays:', error));
    }
  }, [academicYear]);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };
  
  const allEvents = [...events, ...birthdays];

  return (
    <div>
      <Helmet>
        <title>Kalendārs | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      <div className='calendar_container'>
        <Calendar
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 700, width: '100%' }}
          messages={messages}
          tooltipAccessor={(event) => `${event.title} - ${event.location}`}
          onSelectEvent={handleEventSelect}
        />
      </div>

<Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="event-modal-title"
        aria-describedby="event-modal-description"
      >
        <Box sx={modalStyle}>
          {selectedEvent && (
            <>
              <Typography id="event-modal-title" variant="h6" component="h2">
                {selectedEvent.title}
              </Typography>

              {selectedEvent.id.startsWith('b-') ? (
                <>
                  <Typography id="event-modal-description" sx={{ mt: 2 }}>
                    <strong><i className='fa fa-calendar' style={{ paddingRight: '8px' }} /></strong>
                    {moment(selectedEvent.start).format('dddd, D.MMMM YYYY')}
                  </Typography>
                  <Typography>
                    <strong><i className='fa fa-birthday-cake' style={{ paddingRight: '8px' }} /></strong>
                    Laimīgu dzimšanas dienu!
                  </Typography>
                </>
              ) : (
                <>
                  <Typography id="event-modal-description" sx={{ mt: 2 }}>
                    <strong><i className='fa fa-calendar' style={{ paddingRight: '8px' }} /></strong>
                    {moment(selectedEvent.start).format('dddd, D.MMMM YYYY')}
                    {selectedEvent.start.toDateString() !== selectedEvent.end.toDateString() && (
                      <span> - {moment(selectedEvent.end).format('dddd, D.MMMM YYYY')}</span>
                    )}
                  </Typography>
                  <Typography>
                    <strong><i className='fa fa-clock' style={{ paddingRight: '8px' }} /></strong>
                    {moment(selectedEvent.start).format('HH:mm')}
                    {selectedEvent.start.getTime() !== selectedEvent.end.getTime() && (
                      <span> - {moment(selectedEvent.end).format('HH:mm')}</span>
                    )}
                  </Typography>

                  {selectedEvent.location && (
                    <Typography>
                      <strong><i className='fa fa-map-marker' style={{ paddingRight: '8px' }} /></strong> {selectedEvent.location}
                    </Typography>
                  )}
                  {selectedEvent.people && (
                    <Typography>
                      <strong><i className='fa fa-users' style={{ paddingRight: '8px' }} /></strong> {selectedEvent.people}
                    </Typography>
                  )}
                  {selectedEvent.additionalInfo && (
                    <Typography>
                      <strong><i className='fa fa-comment' style={{ paddingRight: '8px' }} /></strong> {selectedEvent.additionalInfo}
                    </Typography>
                  )}
                </>
              )}
              <Button onClick={closeModal} variant="contained" sx={{ mt: 2, backgroundColor: '#6e0b0d' }}>Aizvērt</Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Kalendars;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};