import React, { useState } from 'react';

import ImageControllerLighthouse from './ImageControllerLighthouse';

// Images
import b1 from '../assets/SkolasBildes/1.JPG';
import b2 from '../assets/SkolasBildes/2.JPG';
import b3 from '../assets/SkolasBildes/3.1.JPG';
import b4 from '../assets/SkolasBildes/3.5.JPG';
import b5 from '../assets/SkolasBildes/4.JPG';
import b6 from '../assets/SkolasBildes/5.JPG';
import b7 from '../assets/SkolasBildes/6.JPG';
import b8 from '../assets/SkolasBildes/7.JPG';
import b9 from '../assets/SkolasBildes/8.JPG';
import b10 from '../assets/SkolasBildes/9.JPG';
import b11 from '../assets/SkolasBildes/10.JPG';
import b12 from '../assets/SkolasBildes/11.JPG';
import b13 from '../assets/SkolasBildes/12.JPG';
import b14 from '../assets/SkolasBildes/13.JPG';
import b15 from '../assets/SkolasBildes/14.JPG';
import b16 from '../assets/SkolasBildes/15.JPG';
import b17 from '../assets/SkolasBildes/16.JPG';
import b18 from '../assets/SkolasBildes/17.JPG';
import b19 from '../assets/SkolasBildes/18.JPG';
import b20 from '../assets/SkolasBildes/19.JPG';
import b21 from '../assets/SkolasBildes/20.JPG';
import b22 from '../assets/SkolasBildes/21.JPG';
import b23 from '../assets/SkolasBildes/22.JPG';
import b24 from '../assets/SkolasBildes/23.JPG';
import b25 from '../assets/SkolasBildes/24.JPG';
import b26 from '../assets/SkolasBildes/25.JPG';
import b27 from '../assets/SkolasBildes/26.JPG';
import b28 from '../assets/SkolasBildes/27.JPG';
import b29 from '../assets/SkolasBildes/28.JPG';
import b30 from '../assets/SkolasBildes/29.JPG';
import b31 from '../assets/SkolasBildes/30.JPG';
import b32 from '../assets/SkolasBildes/31.JPG';
import b33 from '../assets/SkolasBildes/32.JPG';
import b34 from '../assets/SkolasBildes/33.JPG';
import b35 from '../assets/SkolasBildes/34.JPG';
import b36 from '../assets/SkolasBildes/35.JPG';
import b37 from '../assets/SkolasBildes/36.JPG';
import b38 from '../assets/SkolasBildes/37.JPG';
import b39 from '../assets/SkolasBildes/38.JPG';

const HPBilzuGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const images = [
        b1, b2, b3, b4, b5, b6, b7, b8, b9, b10,
        b11, b12, b13, b14, b15, b16, b17, b18, b19, b20,
        b21, b22, b23, b24, b25, b26, b27, b28, b29, b30,
        b31, b32, b33, b34, b35, b36, b37, b38, b39
    ];
  
    const [loading, setLoading] = useState(new Array(images.length).fill(true));
  
    const handleImageLoad = (index) => {
        const newLoadingState = [...loading];
        newLoadingState[index] = false;
        setLoading(newLoadingState);
    };
    
    const handleImageClick = (img, index) => {
        setCurrentIndex(index); // Update the current index
        setIsModalOpen(true);    // Open the modal
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
        document.body.style.overflow = 'auto';
    };
  
    return (
        <div>
            <div className='zd_container'>
                <h1 className='zd_title' style={{ borderColor: 'black', paddingTop: '20px' }}>Par skolu</h1>
  
                <div className="containerr">
                    <div className='bg_tab' style={{ paddingBottom: '20px' }}>
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner" role="listbox">
                                {images.map((image, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index} style={{ position: 'relative' }}>
                                        {loading[index] && (
                                            <div></div>
                                        )}
                                        <img
                                            className="d-block gallery-image"
                                            onLoad={() => handleImageLoad(index)}
                                            loading="lazy"
                                            src={image}
                                            alt={`Bilde demostrējoša skolu ${index + 1}`}
                                            onClick={() => handleImageClick(image, index)} // Pass index to handleImageClick
                                            style={{ cursor: 'pointer', display: loading[index] ? 'none' : 'block' }}
                                            onError={() => handleImageLoad(index)}
                                        />
                                    </div>
                                ))}
                                
                                {/* ImageControllerLighthouse for modal functionality */}
                                <ImageControllerLighthouse 
                                    images={images}
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    currentIndex={currentIndex}
                                    setCurrentIndex={setCurrentIndex}
                                />
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
  
            <div className="video-container" style={{paddingBottom: '50px'}}>
                <iframe
                    src="https://www.youtube.com/embed/eZtDANvDkHI?si=E3Cbbj4uZvfhc3bH"
                    title="Video par Jēkabpils Valsts ģimnāziju"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};
  
export default HPBilzuGallery;