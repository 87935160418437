import React, { useEffect, useState } from 'react';
import axios from 'axios';

import EditorComponent from './Editor';
import backendAddress from './BackendAddress';

import { Container, Typography, TextField, MenuItem, Button, Box, Divider, Checkbox, FormControlLabel } from '@mui/material';

//context
import { useHtmlEditor } from './ShowHtmlContext';

const AddNewRaksts = ({ userData }) => {
  const [contentJson, setContentJson] = useState(null);
  const [title, setTitle] = useState('');
  const [sadala, setSadala] = useState('');
  const [rakstaThumbnailUrl, setRakstaThumbnailUrl] = useState('');
  const [link, setLink] = useState('');

  const [incorrectSyntax, setIncorrectSyntax] = useState(false);
  const [syntaxError, setSyntaxError] = useState('');
  const [disabledMainBtn, setDisabledMainBtn] = useState(true);
  const [disabledLinkBtn, setDisabledLinkBtn] = useState(true);
  const [ieklautThumbnail, setIeklautThumbnail] = useState(false);

  const generateLinkFromTitle = () => {
    if (title) {
      const specialCharsMap = {
        ā: 'a', ķ: 'k', š: 's', ģ: 'g', ē: 'e', ī: 'i', ū: 'u', ō: 'o',
        ļ: 'l', č: 'c', ņ: 'n', ž: 'z',
      };
  
      const normalizedTitle = title
        .toLowerCase()
        .replace(/[āķšģēīūōļčņž]/g, match => specialCharsMap[match] || '');
  
      const generatedLink = normalizedTitle
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9-_]/g, '')
  
      setLink(generatedLink);
    }
  };

  useEffect(() => {
    setDisabledLinkBtn(title === '');
  
    const linkPattern = /^[a-zA-Z0-9-_]+$/;
  
    if (link && !linkPattern.test(link) && sadala) {
      setIncorrectSyntax(true);
      setDisabledMainBtn(true);
      setSyntaxError('Nepareiza sintakse! Piemērs: jvg-vesture');
      setDisabledLinkBtn(link  !== '');
    } else {
      setIncorrectSyntax(false);
      setSyntaxError('');
      setDisabledLinkBtn(link  !== '');
  
      if (ieklautThumbnail) {
        setDisabledMainBtn(!(link && title && sadala && rakstaThumbnailUrl !== ''));
      } else {
        setDisabledMainBtn(!(link && title && sadala));
      }
    }
  }, [title, link, sadala, rakstaThumbnailUrl, ieklautThumbnail]);

  //context
  const { showHtmlEditor, setShowHtmlEditor } = useHtmlEditor();

  const handleSave = async () => {
    let thumbnailUrl = rakstaThumbnailUrl;
    if(!ieklautThumbnail){
      thumbnailUrl = 'Nav';
    }
    try {
      const response = await axios.post(`${backendAddress}/api/addraksts`, {
        title: title,
        link: link,
        content: contentJson,
        author: userData.username,
        sadala: sadala,
        thumbnailUrl: thumbnailUrl
      }, {
        headers: {
          'Author': userData.username,
        },
      });
      alert('Raksts veiksmīgi izveidots!');
      console.log('Post saved successfully:', response.data);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        alert(`Error saving post: ${error.response.data.message || error.response.statusText}`);
        console.error('Error saving post:', error.response.data);
      } else {
        alert(`Error saving post: ${error.message}`);
        console.error('Error saving post:', error);
      }
    }
  };

  return (
    <Container>
      <Box mt={14}>
        <Typography variant="h4" gutterBottom>
          Pievienot Jaunu Rakstu
        </Typography>
      </Box>

      <Box mt={2} mb={3}>
        <TextField
          fullWidth
          label="Raksta nosaukums"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Ievadiet raksta nosaukumu šeit."
          variant="outlined"
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Paštaisītais link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Ievadiet linku šeit."
          variant="outlined"
          margin="normal"
          required
          error={incorrectSyntax}
          helperText={syntaxError}
        />
        <Button type="submit" color='primary' fullWidth variant="contained" disabled={disabledLinkBtn} onClick={generateLinkFromTitle}>
            Uzģenerēt link no nosaukuma
        </Button>

        <TextField
          select
          fullWidth
          label="Raksta sadaļa"
          value={sadala}
          onChange={(e) => setSadala(e.target.value)}
          variant="outlined"
          margin="normal"
          required
        >
          <MenuItem value="notikumi">Notikumi</MenuItem>
          <MenuItem value="labais_darbs">Labais Darbs</MenuItem>
          <MenuItem value="latvijas_skolas_soma">Latvijas skolas soma</MenuItem>
        </TextField>

        <FormControlLabel
          control={
            <Checkbox
              checked={ieklautThumbnail}
              onChange={(e) => setIeklautThumbnail(e.target.checked)}
            />
          }
          label="Iekļaut raksta bildi"
        />
        {ieklautThumbnail && (
          <TextField
            fullWidth
            label="Raksta bildes URL"
            value={rakstaThumbnailUrl}
            onChange={(e) => setRakstaThumbnailUrl(e.target.value)}
            placeholder="Ievadiet raksta bildes URL šeit."
            variant="outlined"
            margin="normal"
            helperText="Ieteicams ielikt kaut kādu, jo None izskatīsies ne tik skaisti.."
            required
          />
        )}
      </Box>

      <Divider />

      <Typography variant="body1" mt={3} gutterBottom>
        Ievadiet jauna raksta saturu zemāk un saglabājiet izmaiņas.
      </Typography>

      <EditorComponent setContentJson={setContentJson} contentJson={contentJson} />

      <Divider sx={{ my: 3 }} />

      <Button variant="contained" color="primary" onClick={handleSave} disabled={showHtmlEditor || disabledMainBtn}>
        Saglabāt lapu
      </Button>
    </Container>
  );
};

export default AddNewRaksts;