import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import backendAddress from '../panel_data/components/BackendAddress';

import LoadingAnimation from '../components/LoadingAnimation';

const Notikumi = () => {
  const [notikumi, setNotikumi] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${backendAddress}/api/getLatestNotikumi?category=notikumi&limit=10&page=${page}`);
        setNotikumi(response.data.items || []);
        setTotalPages(response.data.totalPages || 1);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [page]);

  const goToRaksts = (article) => {
    window.location.href = `/ej-uz-rakstu/${article.id}`;
  };

  const renderContent = (content) => {
    return content.map((block, index) => {
      switch (block.type) {
        case 'heading':
          return (
            <h1 key={index} style={{ textAlign: block.attrs.textAlign }}>
              {block.content.map((textNode, idx) => textNode.marks?.some(mark => mark.type === 'bold') ? <strong key={idx}>{textNode.text}</strong> : <span key={idx}>{textNode.text}</span>)}
            </h1>
          );
        case 'paragraph':
          return (
            <p key={index} style={{ textAlign: block.attrs.textAlign }}>
              {block.content.map((textNode, idx) => textNode.marks?.some(mark => mark.type === 'bold') ? <strong key={idx}>{textNode.text}</strong> : <span key={idx}>{textNode.text}</span>)}
            </p>
          );
        default:
          return null;
      }
    });
  };

  const renderArticle = (article) => (
    <div
      className="news-item"
      key={article.id}
      onClick={() => goToRaksts(article)}
      style={styles.newsItem}
    >
      <img
        className="news-image"
        src={article.thumbnailUrl}
        alt="Ziņu bilde"
        style={styles.newsImage}
      />
      <div className="news-content" style={styles.newsContent}>
        <h4 style={styles.title}>{article.title}</h4>
        <p style={styles.date}>
          Publicēts: {new Date(article.published).toLocaleDateString('lv-LV', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })} {new Date(article.published).toLocaleTimeString('lv-LV', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </p>
        <p style={styles.author}>Autors: {article.author}</p>
        {/* <div className="article-content">
          {renderContent(article.content.content)}
        </div> */}
      </div>
    </div>
  );

  return (
    <div className='zd_container_2' style={{marginBottom: '30px', marginTop: '30px'}}>
      <Helmet>
        <title>Notikumi | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      {isLoading ? (
        <LoadingAnimation/>
      ) : (
        <div className='zd_full'>
          <h1 className='zd_title pi_margin_bot'>Notikumi</h1>
          <div>
            <div className='zd_notik_column'>
              {notikumi.length > 0 ? notikumi.map(renderArticle) : <p>Nav pieejamu ziņu</p>}
            </div>
          </div>
          <div aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (page > 1) setPage(page - 1); }}>Iepriekšējā</a>
              </li>
              {[...Array(totalPages).keys()].map(p => (
                <li key={p + 1} className={`page-item ${page === p + 1 ? 'active' : ''}`}>
                  <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); setPage(p + 1); }}>{p + 1}</a>
                </li>
              ))}
              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); if (page < totalPages) setPage(page + 1); }}>Nākošā</a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  newsItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  newsImage: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginRight: '10px'
  },
  newsContent: {
    flex: 1
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0 0 5px'
  },
  date: {
    fontSize: '14px',
    color: '#666'
  },
  author: {
    fontSize: '14px',
    color: '#999'
  }
};

export default Notikumi;