import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Stack, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const Sakums = ({ userData }) => {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Stack alignItems="center" sx={{ mb: 4 }}>
                <HomeIcon color="primary" sx={{ fontSize: 80 }} />
                <Typography variant="h2" component="h1" gutterBottom>
                    Laipni lūgti, {userData?.username || "lietotāj"}!
                </Typography>
            </Stack>
            <div className="pt-3">
                <p>Šajā vietnē jūs varēsiet mainīt mājaslapas datus, veidot jaunas ziņas, pasākumus un lietotājus!</p>
                <p>Lai sāktu strādāt ar šo vietni, izvēlaties vēlamo darbību pa kreisi.</p>
                <p>Jūs esat iegājuši kā {userData.username} ar {userData.perm_level} administrēšanas līmeni.</p>
                <hr />
                <h3>Administrēšanas līmeņu skaidrojums:</h3>
                <p>* 0 līmenis - Visi mājaslapas apmeklētāji. Atļauts skatīt un lietot mājaslapu.</p>
                <p>* 1 līmenis - 0 līmenis + Ļauts veidot un rediģēt pasākumus, rakstus, galerijas un lapas.</p>
                <p>* 2 līmenis - 0-1 līmenis + Ļauts mainīt Fast Access, Navbar un Footer elementus.</p>
                <p>* 3 līmenis - Ļauts pievienot jaunus lietotājus, mainīt tiem paroli un dzēst lietotājus. Šīs tiesības ir tikai Administratora kontam.</p>
            </div>

            <hr></hr>
        </Container>
    );
};

export default Sakums;