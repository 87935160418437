import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import backendAddress from './BackendAddress';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Container, Box } from '@mui/material';

const AddNewUser = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    passwordAtk: '',
    permissionLevel: '1',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [disabledMainBtn, setDisabledMainBtn] = useState(true);

  useEffect(() => {
    if(formData.username && formData.email && formData.password && formData.passwordAtk !== ''){
      if(formData.password!==formData.passwordAtk){
        setDisabledMainBtn(true);
      } else {
        setDisabledMainBtn(false);
      }
    } else {
      setDisabledMainBtn(true);
    }
  }, [formData.username, formData.email, formData.password, formData.passwordAtk])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const session_id = Cookies.get('session');

    if (formData.password !== formData.passwordAtk) {
      alert('Paroles nesakrīt!');
      return;
    }

    try {
      const response = await fetch(`${backendAddress}/api/registernewuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'username': formData.username,
          'password': formData.password,
          'password_again': formData.passwordAtk,
          'email': formData.email,
          'perm_level': formData.permissionLevel,
          'session_id': session_id
        },
        body: JSON.stringify(formData)
      });

      if (response.status === 201) {
        alert('Lietotājs ir veiksmīgi pievienots!');

        setFormData({
          username: '',
          email: '',
          password: '',
          passwordAtk: '',
          permissionLevel: '1'
        });
      } else {
        const errorText = await response.text();
        alert(`Kaut kas nogāja greizi: ${errorText}`);
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      alert('Kaut kas nogāja greizi. Mēģiniet vēlreiz vēlāk.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Izveidot jaunu JVĢ-ADMIN lietotāju
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Lietotāja vārds"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="E-pasts"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Parole"
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Parole atkārtoti"
            id="passwordAtk"
            name="passwordAtk"
            type="password"
            value={formData.passwordAtk}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="permissionLevel-label">Administrēšanas līmenis</InputLabel>
            <Select
              labelId="permissionLevel-label"
              id="permissionLevel"
              name="permissionLevel"
              value={formData.permissionLevel}
              onChange={handleChange}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            disabled={disabledMainBtn}
          >
            Izveidot lietotāju
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AddNewUser;