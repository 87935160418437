import React from 'react';

const LoadingAnimation = () => {
    return (
        <div 
            className="d-flex justify-content-center align-items-center" 
            style={{ minHeight: '75vh' }}
        >
            <div className="spinner-border text-primary loading_spinner" role="status"/>
        </div>
    );
};

export default LoadingAnimation;