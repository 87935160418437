import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Pagination,
  IconButton,
  Dialog,
  TextField,
  Button,
  DialogActions,
  DialogTitle
} from '@mui/material';
import axios from 'axios';
import backendAddress from './BackendAddress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const GalleryEdit = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalGalleries, setTotalGalleries] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editGallery, setEditGallery] = useState(null);
  const [photosDialogOpen, setPhotosDialogOpen] = useState(false);
  const [currentGalleryName, setCurrentGalleryName] = useState(null);
  const [currentGalleryLink, setCurrentGalleryLink] = useState(null);
  const [newPhotos, setNewPhotos] = useState([]);
  const galleriesPerPage = 10;

  const fetchGalleries = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${backendAddress}/api/galleries`, {
        params: { page, limit: galleriesPerPage }
      });
      setGalleries(response.data.galleries);
      setTotalGalleries(response.data.totalCount);
    } catch (error) {
      console.error('Error fetching galleries:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGalleries(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDeleteGallery = async (galleryLink) => {
    if (!window.confirm('Are you sure you want to delete this gallery?')) return;
    try {
      await axios.delete(`${backendAddress}/api/galleries/${galleryLink}`);
      fetchGalleries(page);
    } catch (error) {
      console.error('Error deleting gallery:', error);
      alert('Failed to delete gallery.');
    }
  };

  const handleEditGallery = (gallery) => {
    setEditGallery(gallery);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditGallery(null);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${backendAddress}/api/galleries/${editGallery.gallery_link}`, {
        gallery_name: editGallery.gallery_name,
        gallery_description: editGallery.gallery_description
      });
      handleEditDialogClose();
      fetchGalleries(page);
    } catch (error) {
      console.error('Error updating gallery:', error);
      alert('Failed to update gallery.');
    }
  };

  const handlePhotosDialogOpen = (galleryLink, galleryName) => {
    setCurrentGalleryLink(galleryLink);
    setCurrentGalleryName(galleryName);
    setPhotosDialogOpen(true);
  };

  const handlePhotosDialogClose = () => {
    setPhotosDialogOpen(false);
    setNewPhotos([]);
  };

  const handleAddPhotos = async () => {
    const formData = new FormData();
    newPhotos.forEach((file) => {
      formData.append('files', file);
    });

    try {
      await axios.post(`${backendAddress}/api/galleries/${currentGalleryLink}/photos`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        gallery_name: currentGalleryName,
      });
      handlePhotosDialogClose();
      fetchGalleries(page);
    } catch (error) {
      console.error('Error adding photos:', error);
      alert('Failed to add photos.');
    }
  };

  const handleDeletePhoto = async (galleryLink, filename) => {
    if (!window.confirm('Are you sure you want to delete this photo?')) return;
    try {
      await axios.delete(`${backendAddress}/api/galleries/${galleryLink}/photos/${filename}`);
      fetchGalleries(page);
    } catch (error) {
      console.error('Error deleting photo:', error);
      alert('Failed to delete photo.');
    }
  };

  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Eksistējošās galerijas
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {galleries.map((gallery) => (
            <ListItem
              key={gallery.gallery_link}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderBottom: '1px solid #e0e0e0',
                py: 2
              }}
            >
              <ListItemAvatar>
                <Avatar src={gallery.firstPhoto} alt={gallery.gallery_name} variant="rounded" sx={{ width: 64, height: 64 }} />
              </ListItemAvatar>
              <ListItemText
                primary={gallery.gallery_name}
                secondary={
                  <>
                    <Typography variant="body2" color="text.secondary">
                      Autors: {gallery.author}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Publicēts: {new Date(gallery.publishDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Bilžu skaits: {gallery.totalPhotos}
                    </Typography>
                  </>
                }
                sx={{ ml: 2 }}
              />
              <Box sx={{ display: 'flex' }}>
                <IconButton color="primary" onClick={() => handleEditGallery(gallery)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handleDeleteGallery(gallery.gallery_link, gallery.gallery_name)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handlePhotosDialogOpen(gallery.gallery_link, gallery.gallery_name)}>
                  <PhotoCameraIcon />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
      )}

      <Pagination
        count={Math.ceil(totalGalleries / galleriesPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
      />

      {/* Edit Gallery Dialog */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <Box sx={{ p: 4, width: 400 }}>
          <Typography variant="h6" gutterBottom>
            Edit Gallery
          </Typography>
          <TextField
            fullWidth
            label="Gallery Name"
            variant="outlined"
            margin="normal"
            value={editGallery?.gallery_name || ''}
            onChange={(e) => setEditGallery({ ...editGallery, gallery_name: e.target.value })}
          />
          <TextField
            fullWidth
            label="Gallery Description"
            variant="outlined"
            margin="normal"
            value={editGallery?.gallery_description || ''}
            onChange={(e) => setEditGallery({ ...editGallery, gallery_description: e.target.value })}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handleEditDialogClose} color="secondary">
              Atcelt
            </Button>
            <Button onClick={handleSaveEdit} variant="contained" color="primary">
              Saglabāt
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Add Photos Dialog */}
      <Dialog open={photosDialogOpen} onClose={handlePhotosDialogClose}>
        <Box sx={{ p: 4, width: 400 }}>
          <Typography variant="h6" gutterBottom>
            Add Photos to Gallery
          </Typography>
          <input
            type="file"
            multiple
            onChange={(e) => setNewPhotos([...e.target.files])}
            accept="image/*"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handlePhotosDialogClose} color="secondary">
              Atcelt
            </Button>
            <Button onClick={handleAddPhotos} variant="contained" color="primary">
              Pievienot
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default GalleryEdit;