import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//components
import AddNewUser from '../components/AddNewUser';
import EditUserPass from '../components/EditUserPass';
import DeleteUser from '../components/DeleteUser';
import AddNewPage from '../components/AddNewPage';
import AddNewRaksts from '../components/AddNewRaksts';
import EditFastAccessElements from '../components/EditFastAccessElements';
import EditNavbarElements from '../components/EditNavbarElements';
import EditFooterElements from '../components/EditFooterElements';
import SessionManager from '../components/SessionManager';
import EditPages from '../components/EditPages';
import EditRaksts from '../components/EditRaksts';
import EditEvents from '../components/EditEvents';
import GalleryEdit from '../components/GalleryEdit';
import FileUpload from '../components/FileUpload';
import BirthdayUpload from './BirthdayUpload';
import Sakums from './Sakums';

export default function MainGrid({ selected, userData }) { 
    const renderPages = [
      { id: 0, component: <Sakums userData={userData}/> },
      { id: 1, component: <AddNewUser /> },
      { id: 2, component: <EditUserPass /> },
      { id: 3, component: <DeleteUser /> },
      { id: 4, component: <SessionManager /> },
      { id: 5, component: <EditFastAccessElements userData={userData}/> },
      { id: 6, component: <EditNavbarElements userData={userData}/> },
      { id: 7, component: <EditFooterElements userData={userData}/> },
      { id: 8, component: <BirthdayUpload userData={userData}/> },
      { id: 9, component: <AddNewPage userData={userData}/> },
      { id: 10, component: <EditPages userData={userData}/> },
      { id: 11, component: <AddNewRaksts userData={userData}/> },
      { id: 12, component: <EditRaksts userData={userData}/> },
      { id: 13, component: <EditEvents userData={userData}/> },
      { id: 14, component: <GalleryEdit userData={userData}/> },
      { id: 15, component: <FileUpload userData={userData}/> },
    ];
  
    const currentPage = renderPages.find(page => page.id === selected);
  
    return (
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        {currentPage ? currentPage.component : <Typography>No page selected</Typography>}
      </Box>
    );
  }