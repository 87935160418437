import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backendAddress from '../panel_data/components/BackendAddress';

const GPageZinas = () => {
  const [notikumi, setNotikumi] = useState([]);
  const [labaisDarbs, setLabaisDarbs] = useState([]);
  const [latvijasSkolasSoma, setLatvijasSkolasSoma] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const [notikumiRes, labaisDarbsRes, skolasSomaRes] = await Promise.all([
          axios.get(`${backendAddress}/api/getLatestArticles?category=notikumi&limit=6`),
          axios.get(`${backendAddress}/api/getLatestArticles?category=labais_darbs&limit=6`),
          axios.get(`${backendAddress}/api/getLatestArticles?category=latvijas_skolas_soma&limit=6`),
        ]);

        setNotikumi(notikumiRes.data);
        setLabaisDarbs(labaisDarbsRes.data);
        setLatvijasSkolasSoma(skolasSomaRes.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  const goToRaksts = (article) => {
    window.location.href = `/ej-uz-rakstu/${article.id}`;
  }

  const renderCompactCard = (article) => (
    <div
      className="compact-card"
      key={article.id}
      onClick={() => goToRaksts(article)}
    >
      <img
        src={article.thumbnailUrl}
        alt="Ziņu bilde"
      />
      <div style={{ flex: 1 }}>
        <h5>{article.title}</h5>
        <p>
        <span className='bold-text'>Publicēts:</span> {new Date(article.published).toLocaleDateString('lv-LV', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })} {new Date(article.published).toLocaleTimeString('lv-LV', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </p>
        <p>
        <span className='bold-text'>Autors:</span> {article.author}
        </p>
        <p>
        <span className='bold-text'>Kategorija:</span> {article.sadala}
        </p>
      </div>
    </div>
  );

  return (
    <div className='zd_container'>
      <h1 className='zd_title'>Ziņu dēlis</h1>
      <div className='zd_maintb'>
        <div className='left_puse'>
          <h2 className='zd_subtitle'>Notikumi</h2>
          <div className='zd_notik_column'>
            {notikumi.length > 0 ? notikumi.map(renderCompactCard) : <p>Nav pieejamu ziņu</p>}
          </div>
          <a className='button' href="/zinas/notikumi">Vairāk ziņu</a>
        </div>

        <div className='left_puse'>
          <h2 className='zd_subtitle'>Labais darbs</h2>
          <div className='zd_notik_column'>
            {labaisDarbs.length > 0 ? labaisDarbs.map(renderCompactCard) : <p>Nav pieejamu ziņu</p>}
          </div>
          <a className='button' href="/zinas/labais-darbs">Vairāk ziņu</a>
        </div>

        <div className='left_puse'>
          <h2 className='zd_subtitle'>Latvijas skolas soma</h2>
          <div className='zd_notik_column'>
            {latvijasSkolasSoma.length > 0 ? latvijasSkolasSoma.map(renderCompactCard) : <p>Nav pieejamu ziņu</p>}
          </div>
          <a className='button' href="/zinas/latvijas-skolas-soma">Vairāk ziņu</a>
        </div>
      </div>
    </div>
  );
};

export default GPageZinas;