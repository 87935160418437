//css imports
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import 'react-notifications/lib/notifications.css';

//modules
import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';

//components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Scroll from './components/Scroll';
import FastAccess from './components/FastAccess';
import ScrollToTopButton from './components/ScrollToTopMobile';
// import LoadingAnimation from './components/LoadingAnimation';
import CustomBreadcrumbs from './components/Breadcrumbs';
import CookiePolicyLogs from './components/CookiePolicyLogs';

//lapas
import Galvena from './pages/GalvenaLapa';
import NavAtrasta from './pages/NavAtrasta';
import RenderPage from './pages/RenderPages';
import RenderRakstus from './pages/RenderRakstus';
import Notikumi from './pages/Notikumi';
import Search from './pages/Search';
import LabieDarbi from './pages/LabieDarbi';
import LatvijasSkolasSoma from './pages/LatvijasSkolasSoma';
import Galerija from './pages/Galerija';
import RenderGallery from './pages/RenderGallery';
import EjUz from './pages/EjUz';
import Vesture from './pages/Vesture';
import Pasakumi from './pages/Pasakumi';
import Sadala from './pages/RenderSadala';
import Kalendars from './pages/Calendar';

//lapa_panelei
import Panele from './panel_data/pages/Panele';

//global context
import { HtmlEditorProvider } from './panel_data/components/ShowHtmlContext';

function App() {
	const [isPanel, setIsPanel] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [isNavbar, setIsNavbar] = useState(false);
	
    useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	const determinePageLocation = () => {
		if (window.location.href.includes('/jvgadmin')) {
			setIsPanel(false);
		} else {
			setIsPanel(true);
		}

		if (location.pathname === '/'){
			setIsNavbar(false);
			console.log('hide navbar')
		} else {
			setIsNavbar(true);
			console.log('show navbar')
		}
	};

	useEffect(() => {
		determinePageLocation();
	}, [location.pathname]);
	
	return (
		<div className='fade-in'>
            {isPanel && (
                <>
                    <FastAccess />
					{isNavbar && (
						<>
							<Navbar />
						</>
					)}
                    {/* <Scroll /> */}
					<CustomBreadcrumbs />
                </>
            )}

			<HtmlEditorProvider>
			<Routes>
				{/* visparigas lapas */}
				<Route path="/" element={<Galvena />} />
				<Route path="*" element={<NavAtrasta />} />
				<Route path="/zinas/notikumi" element={<Notikumi/>} />
				<Route path="/zinas/labais-darbs" element={<LabieDarbi/>} />
				<Route path="/zinas/latvijas-skolas-soma" element={<LatvijasSkolasSoma/>} />
				<Route path="/meklet" element={<Search/>} /> {/* to finish */}
				<Route path="/galerija" element={<Galerija/>} /> {/* to finish */}
				<Route path="/vesture" element={<Vesture/>} /> {/* to finish */}
				<Route path="/pasakumi" element={<Pasakumi/>} /> {/* to finish */}
				<Route path="/kalendars" element={<Kalendars/>} /> {/* to finish */}

				{/* test pages!!! on prod remove!!!!!!!!!!!!!!!!!!! */}
				{/* <Route path="/loadingtest" element={<LoadingAnimation />} /> */}

				{/* adm panele */}
				<Route path="/jvgadmin" element={<Panele />} />
				

				{/* dynamic render pages */}
				<Route path="/lapa/:id" element={<RenderPage NotificationManager={NotificationManager} />} />
				<Route path="/galerija/:id" element={<RenderGallery NotificationManager={NotificationManager} />} />

				<Route path="/zinas/notikumi/:id" element={<RenderRakstus NotificationManager={NotificationManager} />} />
				<Route path="/zinas/labie-darbi/:id" element={<RenderRakstus NotificationManager={NotificationManager} />} />
				<Route path="/zinas/latvijas-skolas-soma/:id" element={<RenderRakstus NotificationManager={NotificationManager} />} />
				<Route path="/ej-uz-rakstu/:id" element={<EjUz type='raksts' />} />
				<Route path="/ej-uz-lapu/:id" element={<EjUz type='lapa' />} />
				<Route path="/sadala/:sadala" element={<Sadala />} /> {/* to finish */}
			</Routes>
			</HtmlEditorProvider>
			
			{isPanel && <Footer />}

			<NotificationContainer/>

			<ScrollToTopButton/>
			<CookiePolicyLogs/>
		</div>
	);
}

export default App;
