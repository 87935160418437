import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Gallery from '../components/Gallery';
import HPBilzuGallery from '../components/HPBilzuGallrey';
import EventsSmall from '../components/EventsSmall';
import GPageZinas from '../components/GPageZinas';
import skolasBilde from '../assets/SkolasBildes/1.JPG';
import LoadingAnimation from '../components/LoadingAnimation';
import Navbar from '../components/Navbar';
import { Container, Grid } from '@mui/material';

const Galvena = () => {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const img = new Image();
        img.src = skolasBilde;
        
        img.onload = () => {
            console.log('Image loaded successfully');
            setLoading(false);
        };
        
        img.onerror = () => {
            console.error('Error loading image');
            setLoading(false);
        };
    }, []);

    return (
        <div>
            <Helmet>
                <link rel="preload" href={skolasBilde} as="image" />
                <title>Galvenā lapa | Jēkabpils Valsts ģimnāzija</title>
            </Helmet>
			
            {loading ? (
                <LoadingAnimation/>
            ) : (
                <div className='container-main'>
                    <div className='school_bg'>
                        <Navbar/>
                        <div className='gl_sad1' data-parallax="true">
                            <div className='container' style={{paddingTop: '40px'}}>
                                <h1 className='title h1'>Jēkabpils Valsts ģimnāzija</h1>
                                <Gallery/>
                            </div>
                        </div>
                    </div>

                    <div className='container_wrapper'>
                        <div className='container_galv'>
                            <div className='container_left'>
                                <GPageZinas/>
                                <HPBilzuGallery/>
                            </div>
                            <div className='container_right'>
                                <EventsSmall/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Galvena;