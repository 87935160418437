import React, { useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/themes/prism-coy.css';
import 'prismjs/components/prism-markup';
import { prettify } from 'htmlfy';

const HTMLEditorComponent = ({ htmlCode, setHtmlCode, displayHtml }) => {
    useEffect(() => {
        setHtmlCode(prettify(htmlCode));
    }, []);

    return (
        <div className="html_editor" style={{ padding: 10 }}>
            <Editor
                value={htmlCode}
                onValueChange={(code) => setHtmlCode(code)}
                highlight={(code) => highlight(code, languages.markup, 'markup')}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                    color: '#ffffff',
                    minHeight: '300px',
                    overflowY: 'auto',
                }}
            />
        </div>
    );
};

export default HTMLEditorComponent;