import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { 
    Button, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Checkbox, 
    IconButton, 
    Collapse, 
    Typography, 
    Paper, 
    Box 
} from '@mui/material';
import { Add, Delete, ExpandMore, Search } from '@mui/icons-material';
import logo from '../../assets/logo.png';
import menuIcon from '../../assets/menuIcon.svg';

import backendAddress from './BackendAddress';

const EditNavbarElements = () => {
    const [navbarElements, setNavbarElements] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    useEffect(() => {
        fetch(`${backendAddress}/api/getnavbarelements`)
            .then(response => response.json())
            .then(data => setNavbarElements(data))
            .catch(error => console.error('Error fetching navbar elements:', error));
    }, []);

    const handleChange = (index, key, value) => {
        const updatedElements = [...navbarElements];
        
        if (key === 'id') {
            const newId = parseInt(value);
            const conflictingElementIndex = updatedElements.findIndex(el => el.id === newId);
    
            if (conflictingElementIndex !== -1 && conflictingElementIndex !== index) {
                const tempId = updatedElements[conflictingElementIndex].id;
                updatedElements[conflictingElementIndex].id = updatedElements[index].id;
                updatedElements[index].id = newId;
            } else {
                updatedElements[index].id = newId;
            }
        } else {
            updatedElements[index] = { ...updatedElements[index], [key]: value };
        }
    
        updatedElements.sort((a, b) => a.id - b.id);
        setNavbarElements(updatedElements);
    };
    
    const handleDropdownChange = (elementIndex, dropdownIndex, key, value) => {
        const updatedElements = [...navbarElements];
        const dropdownElements = [...updatedElements[elementIndex].dropdownElements];
    
        if (key === 'id') {
            const newId = parseInt(value);
            const conflictingDropdownIndex = dropdownElements.findIndex(el => el.id === newId);
    
            if (conflictingDropdownIndex !== -1 && conflictingDropdownIndex !== dropdownIndex) {
                const tempId = dropdownElements[conflictingDropdownIndex].id;
                dropdownElements[conflictingDropdownIndex].id = dropdownElements[dropdownIndex].id;
                dropdownElements[dropdownIndex].id = newId;
            } else {
                dropdownElements[dropdownIndex].id = newId;
            }
        } else {
            dropdownElements[dropdownIndex] = { ...dropdownElements[dropdownIndex], [key]: value };
        }
    
        updatedElements[elementIndex].dropdownElements = dropdownElements;

        updatedElements[elementIndex].dropdownElements.sort((a, b) => a.id - b.id);
        setNavbarElements(updatedElements);
    };

    const addNewElement = (type = 'standart') => {
        const newElement = {
            id: navbarElements.length,
            link: '',
            name: '',
            type: type,
            newTab: false,
            dropdownElements: type === 'dropdown' ? [] : undefined
        };
        setNavbarElements([...navbarElements, newElement]);
    };

    const addNewDropdownElement = (elementIndex) => {
        const updatedElements = [...navbarElements];
        const newDropdownElement = { id: updatedElements[elementIndex].dropdownElements.length, link: '', name: '', newTab: false };
        updatedElements[elementIndex].dropdownElements.push(newDropdownElement);
        setNavbarElements(updatedElements);
    };

    const saveChanges = () => {
        setIsSaving(true);
        fetch(`${backendAddress}/api/savenavbarelements`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ updatedElements: navbarElements })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.message);
            alert('Navbar Elements: Izmaiņas veiksmīgi saglabātas!');
        })
        .catch(error => {
            console.error('Error saving navbar elements:', error);
            alert('Navbar Elements: Neizdevās saglabāt elementus!');
        })
        .finally(() => {
            window.location.reload();
        });
    };

    const handleNavToggle = () => setIsNavCollapsed(!isNavCollapsed);

    const handleDelete = (index) => {
        const updatedElements = [...navbarElements];
        updatedElements.splice(index, 1);
        const reIndexedElements = updatedElements.map((element, newIndex) => ({
            ...element,
            id: newIndex
        }));
        setNavbarElements(reIndexedElements);
    };
    
    const handleDropdownDelete = (index, dropdownIndex) => {
        const updatedElements = [...navbarElements];
        updatedElements[index].dropdownElements.splice(dropdownIndex, 1);
        const reIndexedDropdownElements = updatedElements[index].dropdownElements.map((element, newIndex) => ({
            ...element,
            id: newIndex
        }))
        updatedElements[index].dropdownElements = reIndexedDropdownElements;
        setNavbarElements(updatedElements);
    };

    const currentDomain = window.location.origin;

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>Rediģēt navbar elementus</Typography>
            <Typography variant="body1" gutterBottom>!!! Obligāti jābūt pievienotiem !!!:</Typography>
            <ul>
                <li>{currentDomain}/zinas/notikumi</li>
                <li>{currentDomain}/zinas/labais-darbs</li>
                <li>{currentDomain}/zinas/latvijas-skolas-soma</li>
                <li>{currentDomain}/pasakumi</li>
                <li>{currentDomain}/skolas-vesture</li>
                <li>{currentDomain}/galerija</li>
            </ul>
            <Button 
                variant="contained" 
                color="primary" 
                startIcon={<Add />} 
                sx={{ marginRight: 2, marginBottom: 3 }} 
                onClick={() => addNewElement('standart')}
            >
                Pievienot jaunu parasto elementu
            </Button>
            <Button 
                variant="contained" 
                color="primary" 
                startIcon={<Add />} 
                sx={{ marginBottom: 3 }} 
                onClick={() => addNewElement('dropdown')}
            >
                Pievienot jaunu elementu ar grupējumu
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Saite</TableCell>
                            <TableCell>Vārds</TableCell>
                            <TableCell>Tips</TableCell>
                            <TableCell>Jaunā cilnē?</TableCell>
                            <TableCell>Dzēst?</TableCell>
                            <TableCell>Grupas elementi / Jaunā cilnē? / Dzēst?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {navbarElements.map((element, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={element.id}
                                        onChange={(e) => handleChange(index, 'id', parseInt(e.target.value))}
                                        size="small"
                                        placeholder='Elementa ID'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        value={element.link}
                                        onChange={(e) => handleChange(index, 'link', e.target.value)}
                                        size="small"
                                        disabled={element.type === 'dropdown'}
                                        placeholder='Parastā el. saite'
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        value={element.name}
                                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                                        size="small"
                                        placeholder='Elementa nosauk.'
                                    />
                                </TableCell>
                                <TableCell>{element.type}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={element.newTab}
                                        onChange={(e) => handleChange(index, 'newTab', e.target.checked)}
                                        disabled={element.type === 'dropdown'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton color="error" onClick={() => handleDelete(index)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    {element.type === 'dropdown' && (
                                        <>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={() => addNewDropdownElement(index)}
                                            >
                                                Pievienot grupas elementu
                                            </Button>
                                            {element.dropdownElements.map((dropdownElement, dropdownIndex) => (
                                                <Box key={dropdownIndex} sx={{ marginTop: 2 }}>
                                                    <TextField
                                                        type="number"
                                                        value={dropdownElement.id}
                                                        onChange={(e) => handleDropdownChange(index, dropdownIndex, 'id', parseInt(e.target.value))}
                                                        size="small"
                                                        sx={{ marginRight: 2 }}
                                                        placeholder='Elementa ID'
                                                    />
                                                    <TextField
                                                        type="text"
                                                        value={dropdownElement.link}
                                                        onChange={(e) => handleDropdownChange(index, dropdownIndex, 'link', e.target.value)}
                                                        size="small"
                                                        sx={{ marginRight: 2 }}
                                                        placeholder='Saite'
                                                    />
                                                    <TextField
                                                        type="text"
                                                        value={dropdownElement.name}
                                                        onChange={(e) => handleDropdownChange(index, dropdownIndex, 'name', e.target.value)}
                                                        size="small"
                                                        placeholder='Grupas elementa nosaukums'
                                                        sx={{ marginRight: 2 }}
                                                    />
                                                    <Typography variant="paragraph">Jaunā cilnē?</Typography>
                                                    <Checkbox
                                                        checked={dropdownElement.newTab}
                                                        onChange={(e) => handleDropdownChange(index, dropdownIndex, 'newTab', e.target.checked)}
                                                        sx={{ marginRight: 2 }}
                                                    />
                                                    <IconButton color="error" onClick={() => handleDropdownDelete(index, dropdownIndex)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <hr></hr>
                <h2>Priekšskats:</h2>

                <nav className='navbar navbar-expand-lg navbar-el'>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={!isNavCollapsed}
                        aria-label="Toggle navigation"
                        onClick={handleNavToggle}
                    >
                        <img src={menuIcon} width="20" height="20" alt="Menu Icon" />
                    </button>

                    <Link to="/">
                        <img src={logo} width="95" height="97" alt="Logo" />
                    </Link>

                    <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {navbarElements.map((element) =>
                                element.type === 'standart' ? (
                                    <li key={element.id} className="nav-item">
                                        <a href={element.link} className="nav-link" target={element.newTab ? "_blank" : "_self"} rel={element.newTab ? "noopener noreferrer" : undefined}>
                                            {element.name}
                                        </a>
                                    </li>
                                ) : (
                                    <li key={element.id} className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id={`navbarDropdown-${element.id}`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {element.name}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby={`navbarDropdown-${element.id}`}>
                                            {element.dropdownElements.map((dropdownElement) => (
                                                <a key={dropdownElement.id} className="dropdown-item" href={dropdownElement.link} target={dropdownElement.newTab ? "_blank" : "_self"} rel={dropdownElement.newTab ? "noopener noreferrer" : undefined}>
                                                    {dropdownElement.name}
                                                </a>
                                            ))}
                                        </div>
                                    </li>
                                )
                            )}

                            <li className="nav-item">
                                <Link to="/search" className="nav-link">
                                    <i className="fas fa-search"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>

                <hr></hr>

            <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 3 }}
                onClick={saveChanges}
                disabled={isSaving}
            >
                Saglabāt izmaiņas
            </Button>
        </Box>
    );
};

export default EditNavbarElements;