import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import backendAddress from './BackendAddress';

import { Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, CircularProgress, Alert } from '@mui/material';

const EditUserPass = () => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    password: '',
    passwordAtk: '',
  });

  const [disabledMainBtn, setDisabledMainBtn] = useState(true);

  useEffect(() => {
    const isPasswordValid = formData.password && formData.passwordAtk !== '';
    const isPasswordMatch = formData.password === formData.passwordAtk;
    
    if (isPasswordValid && isPasswordMatch) {
      if (selectedUserId != '1' && selectedUserId != '') {
        setDisabledMainBtn(false);
      } else {
        setDisabledMainBtn(true);
      }
    } else {
      setDisabledMainBtn(true);
    }
  }, [selectedUserId, formData.password, formData.passwordAtk]);

  const fetchAllUsers = async () => {
    const session_id = Cookies.get('session');
    try {
      const response = await fetch(`${backendAddress}/api/getallusers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'session_id': session_id
        }
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const users = await response.json();
      setAllUsers(users);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangePassword = async () => {
    const sessionToken = Cookies.get('session');

    if (!selectedUserId) {
      alert('Please select a user.');
      return;
    }

    if (selectedUserId === '1') {
      alert('Changing the administrator password is not allowed!');
      return;
    }

    if (formData.password !== formData.passwordAtk) {
      alert('Passwords do not match!');
      return;
    }

    try {
      const response = await fetch(`${backendAddress}/api/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'session_id': sessionToken,
        },
        body: JSON.stringify({
          userId: selectedUserId,
          password: formData.password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Password successfully changed!');
        setError('');
      } else {
        setError(`Error: ${data.message}`);
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setError('An error occurred. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Nomainīt paroli lietotājam
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            Pieejamie lietotāji:
          </Typography>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
            >
              <MenuItem value="">
                <em>Izvēlaties lietotāju</em>
              </MenuItem>
              {allUsers.map(user => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username} (ID: {user.id}) - {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              margin="normal"
              label="Jauna parole"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Atkārtoti jauna parole"
              type="password"
              name="passwordAtk"
              value={formData.passwordAtk}
              onChange={handleChange}
              required
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleChangePassword}
              sx={{ mt: 2 }}
              disabled={disabledMainBtn}
            >
              Nomainīt paroli
            </Button>
          </Box>

          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </>
      )}
    </Container>
  );
};

export default EditUserPass;