import React, { useState, useEffect } from 'react';
import axios from 'axios';
import backendAddress from './BackendAddress';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Pagination,
  Divider
} from '@mui/material';

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchFiles();
  }, [currentPage]);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendAddress}/api/files`, {
        params: { page: currentPage, search: searchQuery },
      });
      setFiles(response.data.files);
      setFilteredFiles(response.data.files);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFiles) {
      setErrorMessage('Nav izvēlēti faili augšupielādēšanai.');
      return;
    }
    const formData = new FormData();
    Array.from(selectedFiles).forEach(file => formData.append('files', file));

    try {
      setLoading(true);
      await axios.post(`${backendAddress}/api/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      fetchFiles();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
      setSelectedFiles(null);
    }
  };

  const handleDeleteFile = async (filename) => {
    try {
      setLoading(true);
      await axios.delete(`${backendAddress}/api/files/${filename}`);
      fetchFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkCopy = async (filename) => {
    try {
      const response = await axios.get(`${backendAddress}/api/copylink/${filename}`, {
        params: { backendAddress: backendAddress },
      });
      let link = response.data.link;
  
      await navigator.clipboard.writeText(link);
      alert('Saite ir veiksmīgi nokopēta!');
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchFiles();
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container>
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>Menedžēt failus uz servera</Typography>
        
        <Box sx={{ maxWidth: 500, mx: 'auto', mb: 4 }}>
          <Typography variant="h6" gutterBottom>Augšupielādēt failus</Typography>
          <TextField
            type="file"
            inputProps={{ multiple: true }}
            fullWidth
            onChange={(e) => setSelectedFiles(e.target.files)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleFileUpload}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Augšupielādēt failus'}
          </Button>
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>{errorMessage}</Typography>
          )}
        </Box>

        <TextField
          label="Meklēt failus"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 3 }}
        />
        <Button variant="contained" color="primary" onClick={handleSearch} sx={{ mr: 2 }}>
          Meklēt
        </Button>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>Augšupielādētie faili:</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {filteredFiles.map((file) => (
              <Card key={file.filename} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, p: 2, boxShadow: 3, borderRadius: 2 }}>
                <CardContent sx={{ flexGrow: 1, padding: 0 }}>
                  <Typography variant="body1" noWrap sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {file.filename}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleLinkCopy(file.filename)}
                    sx={{ mb: 1, width: 120 }}
                  >
                    Kopēt saiti
                  </Button>
                  <Button
                    size="small"
                    color="error"
                    onClick={() => handleDeleteFile(file.filename)}
                    sx={{ width: 120 }}
                  >
                    Dzēst
                  </Button>
                </Box>
              </Card>
            ))}
          </Box>
        )}

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          sx={{ mt: 4 }}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">Fails veiksmīgi augšupielādēts!</Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default FileUpload;