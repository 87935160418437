import React, { useEffect, useState } from 'react';
import LoadingAnimation from './LoadingAnimation';

const ImageControllerLighthouse = ({ images, isModalOpen, setIsModalOpen, currentIndex, setCurrentIndex }) => {
    const [selectedImage, setSelectedImage] = useState(images[currentIndex]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);

    useEffect(() => {
        setSelectedImage(images[currentIndex]);
        setIsLoading(true);
        setLoadError(false);
    }, [currentIndex, images]);

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const goToNext = () => {
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(images[nextIndex]);
        setCurrentIndex(nextIndex);
        setIsLoading(true);
        setLoadError(false);
    };

    const goToPrevious = () => {
        const prevIndex = (currentIndex - 1 + images.length) % images.length;
        setSelectedImage(images[prevIndex]);
        setCurrentIndex(prevIndex);
        setIsLoading(true);
        setLoadError(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            // Add event listener for Escape key
            window.addEventListener('keydown', handleKeyDown);
        }
        // Cleanup the event listener on unmount or modal close
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    return (
        <div>
            {isModalOpen && (
                <div className="image-lightgouse-modal" onClick={closeModal} aria-live="polite">
                    <button className="lightroom_close" onClick={closeModal}>&times;</button>
                    <button className="prev-button" onClick={(e) => { e.stopPropagation(); goToPrevious(); }}>&lt;</button>
                    {isLoading && <LoadingAnimation />}
                    <img
                        src={selectedImage}
                        alt="Bilde no lapas"
                        className="modal-image"
                        onLoad={() => { setIsLoading(false); setLoadError(false); }}
                        onError={() => {
                            setLoadError(true);
                            setIsLoading(false);
                        }}
                        style={{ display: isLoading ? 'none' : 'block' }}
                        aria-busy={isLoading}
                    />
                    {loadError && <div className="error-message" style={{color: '#fff'}}>Neizdevās ielādēt bildi. Mēģiniet vēlreiz.</div>}
                    <button className="next-button" onClick={(e) => { e.stopPropagation(); goToNext(); }}>&gt;</button>
                </div>
            )}
        </div>
    );
};

export default ImageControllerLighthouse;