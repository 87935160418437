import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backendAddress from './BackendAddress';
import {
  Button,
  Card,
  CardContent,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider,
  Container,
  Checkbox,
  FormControlLabel
} from '@mui/material';

const EditEvents = () => {
  const [academicYears, setAcademicYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    id: null,
    name: '',
    location: '',
    people: '',
    additionalInfo: '',
    date: '',
    endDate: '',
    time: ''
  });
  const [includeTime, setIncludeTime] = useState(false);
  const [dateRange, setDateRange] = useState(false);
  const [timeRange, setTimeRange] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [disabledMainBtn, setDisabledMainBtn] = useState(true);

  useEffect(() => {
    const allFieldsFilled = newEvent.name && newEvent.location && newEvent.people && newEvent.additionalInfo && newEvent.date;
  
    let shouldDisable = true;
  
    if (allFieldsFilled) {
      if (includeTime) {
        if (newEvent.time !== '' && newEvent.endTime !== '') {
          shouldDisable = false;
        } else if (!newEvent.time && !newEvent.endTime) {
          shouldDisable = true;
        }
      } else {
        shouldDisable = false;
      }
  
      if (dateRange && newEvent.endDate !== '') {
        shouldDisable = false;
      }
  
      if (!dateRange && !includeTime) {
        shouldDisable = false;
      }
    }
  
    if (!allFieldsFilled) {
      shouldDisable = true;
    }
  
    setDisabledMainBtn(shouldDisable);
  }, [
    newEvent.name, 
    newEvent.location, 
    newEvent.people, 
    newEvent.additionalInfo, 
    newEvent.date, 
    newEvent.time, 
    newEvent.endDate, 
    newEvent.endTime, 
    dateRange, 
    timeRange,
    includeTime
  ]);

  useEffect(() => {
    axios.get(`${backendAddress}/api/getAcademicYears`)
      .then(response => setAcademicYears(response.data))
      .catch(error => console.error(error));
  }, []);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    axios.get(`${backendAddress}/api/getEvents/${year}`)
      .then(response => {
        const sortedEvents = sortEventsByDate(response.data);
        setEvents(sortedEvents);
      })
      .catch(error => console.error(error));
  };

  const addNewYear = () => {
    const newYear = prompt("Enter the new academic year:");
    if (newYear) {
      axios.post(`${backendAddress}/api/addAcademicYear`, { macibu_gads: newYear })
        .then(response => {
          setAcademicYears([...academicYears, newYear]);
          setSelectedYear(newYear);
          setEvents([]);
        })
        .catch(error => console.error(error));
    }
  };

  const handleAddOrEditEvent = () => {
    let updatedEvents;
    if (isEditing) {
      updatedEvents = events.map(event => (event.id === newEvent.id ? newEvent : event));
    } else {
      const newEventId = events.length;
      const event = { ...newEvent, id: newEventId };
      updatedEvents = [...events, event];
    }

    // Sort events after adding or editing
    updatedEvents = sortEventsByDate(updatedEvents);
    setEvents(updatedEvents);

    setNewEvent({
      id: null,
      name: '',
      location: '',
      people: '',
      additionalInfo: '',
      date: '',
      endDate: '',
      time: '',
      endTime: ''
    });
    setIsEditing(false);
    toggleDialog();
  };

  const handleEditEvent = (event) => {
    setNewEvent(event);
    setIsEditing(true);
    toggleDialog();
  };

  const handleDeleteEvent = (id) => {
    const updatedEvents = events.filter(event => event.id !== id);
    // Sort events after deletion
    setEvents(sortEventsByDate(updatedEvents));
  };

  const handleSave = () => {
    const data = {
      mg: selectedYear,
      pasakumi: events
    };
    axios.post(`${backendAddress}/api/saveEvents`, data)
      .then(response => alert(`Pasākumi mācību gadam ${selectedYear} ir saglabāti!`))
      .catch(error => console.error(error));
  };

  const sortEventsByDate = (events) => {
    return events.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

  return (
    <Container>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Pārvaldīt Pasākumus
          </Typography>
          <Typography align="center" paragraph>
            Izvēlieties mācību gadu un pārvaldiet ar to saistītos pasākumus
          </Typography>

          <TextField
            select
            label="Izvēlaties Mācību Gadu"
            fullWidth
            value={selectedYear}
            onChange={handleYearChange}
            sx={{ mb: 3 }}
          >
            <MenuItem value="">
              <em>Pieejamie gadi</em>
            </MenuItem>
            {academicYears.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>

          <Button variant="contained" onClick={addNewYear} sx={{ mb: 3 }}>
            Pievienot Jaunu Mācību Gadu
          </Button>

          <Divider sx={{ my: 3 }} />

          {selectedYear && (
            <>
              <Typography variant="h5" align="center" gutterBottom>
                Pasākumi {selectedYear}-{parseInt(selectedYear) + 1} Mācību Gadam
              </Typography>

              {events.length > 0 ? (
                <ul>
                  {events.map(event => (
                    <li key={event.id}>
                      <Typography>
                          <strong>{event.name} - </strong>{formatDate(event.date)}{event.endDate && ` līdz ${formatDate(event.endDate)}`} {includeTime && event.time}{includeTime && event.endTime && ` līdz ${event.endTime}`}
                      </Typography>
                      <Button variant="text" color="primary" onClick={() => handleEditEvent(event)}>
                        Rediģēt
                      </Button>
                      <Button variant="text" color="secondary" onClick={() => handleDeleteEvent(event.id)}>
                        Dzēst
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography align="center">Nav ieplānoto pasākumu šim mācību gadam.</Typography>
              )}

              <Button variant="contained" color="success" onClick={toggleDialog}>
                {isEditing ? 'Rediģēt Pasākumu' : 'Pievienot Jaunu Pasākumu'}
              </Button>
            </>
          )}

          {selectedYear && (
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ ml: 1 }}>
              Saglabāt Pasākumus
            </Button>
          )}
        </CardContent>
      </Card>

      <Dialog open={dialogOpen} onClose={toggleDialog}>
        <DialogTitle>{isEditing ? 'Rediģēt Pasākumu' : 'Pievienot Jaunu Pasākumu'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lūdzu ievadiet pasākuma detaļas zemāk.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Pasākuma Nosaukums"
            fullWidth
            value={newEvent.name}
            onChange={e => setNewEvent({ ...newEvent, name: e.target.value })}
            required
          />
          <TextField
            margin="dense"
            label="Pasākuma Norises Vieta"
            fullWidth
            value={newEvent.location}
            onChange={e => setNewEvent({ ...newEvent, location: e.target.value })}
            required
          />
          <TextField
            margin="dense"
            label="Kam Domāts Pasākums?"
            fullWidth
            value={newEvent.people}
            onChange={e => setNewEvent({ ...newEvent, people: e.target.value })}
            required
          />
          <TextField
            margin="dense"
            label="Papildus Informācija"
            fullWidth
            value={newEvent.additionalInfo}
            onChange={e => setNewEvent({ ...newEvent, additionalInfo: e.target.value })}
            required
          />

          <FormControlLabel
            control={<Checkbox checked={dateRange} onChange={() => setDateRange(!dateRange)} />}
            label="Datuma diapazons"
          />
          <TextField
            margin="dense"
            label="Sākuma Datums"
            type="date"
            fullWidth
            value={newEvent.date}
            onChange={e => setNewEvent({ ...newEvent, date: e.target.value })}
            InputLabelProps={{ shrink: true }}
            required
          />
          {dateRange && (
            <TextField
              margin="dense"
              label="Beigu Datums"
              type="date"
              fullWidth
              value={newEvent.endDate}
              onChange={e => setNewEvent({ ...newEvent, endDate: e.target.value })}
              InputLabelProps={{ shrink: true }}
              required
            />
          )}

          <FormControlLabel
            control={<Checkbox checked={includeTime} onChange={() => setIncludeTime(!includeTime)} />}
            label="Iekļaut Laiku"
          />
          {includeTime && (
            <>
              <FormControlLabel
                control={<Checkbox checked={timeRange} onChange={() => setTimeRange(!timeRange)} />}
                label="Izvēlieties Laika Diapazonu"
              />
              {!timeRange ? (
                <TextField
                  margin="dense"
                  label="Laiks"
                  type="time"
                  fullWidth
                  value={newEvent.time}
                  onChange={e => setNewEvent({ ...newEvent, time: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              ) : (
                <>
                  <TextField
                    margin="dense"
                    label="Sākuma Laiks"
                    type="time"
                    fullWidth
                    value={newEvent.time}
                    onChange={e => setNewEvent({ ...newEvent, time: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  <TextField
                    margin="dense"
                    label="Beigu Laiks"
                    type="time"
                    fullWidth
                    value={newEvent.endTime}
                    onChange={e => setNewEvent({ ...newEvent, endTime: e.target.value })}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddOrEditEvent} color="primary" disabled={disabledMainBtn}>
            {isEditing ? 'Saglabāt Izmaiņas' : 'Pievienot Pasākumu'}
          </Button>
          <Button onClick={toggleDialog} color="secondary">
            Aizvērt
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditEvents;