import '../css/navbar.css';
import logo from '../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
import menuIcon from '../assets/menuIcon.svg';
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import backendAddress from '../panel_data/components/BackendAddress';

const Navbar = () => {
    const location = useLocation();
    const [navbarElements, setNavbarElements] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const navbarRef = useRef(null);

    useEffect(() => {
        fetch(`${backendAddress}/api/getnavbarelements`)
            .then(response => response.json())
            .then(data => setNavbarElements(data))
            .catch(error => console.error('Error fetching navbar elements:', error));

        setIsTouchDevice(('ontouchstart' in window) || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    }, []);

    useEffect(() => {
        console.log('isTouchDevice:', isTouchDevice);
    }, [isTouchDevice]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setDropdownOpen({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarRef]);

    const handleDropdownToggle = (id) => {
        setDropdownOpen((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = false;
            });
            return { ...newState, [id]: !prevState[id] };
        });
    };

    const handleCloseDropdown = (id) => {
        setDropdownOpen((prevState) => ({ ...prevState, [id]: false }));
    };

    if (location.pathname === "/") {
        return (
            <nav className="navbar navbar-expand-lg navbar-el navbar-left-colored" ref={navbarRef}>
                {/* Logo */}
                <Link to="/">
                    <img className='wpage_logo' src={logo} alt="Logo" />
                </Link>
                {/* Toggler icon for small screens */}
                <button
                    className="navbar-toggler ham_button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <img src={menuIcon} width="20" height="20" alt="Menu Icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '20px !important' }}>
                    <ul className="navbar-nav ml-auto navbar-right">
                        {navbarElements.map(element => (
                            element.type === 'standart' ? (
                                <li key={element.id} className="nav-item">
                                    <a href={element.link} className="nav-link" target={element.newTab ? "_blank" : "_self"} rel={element.newTab ? "noopener noreferrer" : undefined}>
                                        {element.name}
                                    </a>
                                </li>
                            ) : (
                                <div
                                    key={element.id}
                                    className={`nav-item dropdown ${dropdownOpen[element.id] ? 'show' : ''}`}
                                    onMouseEnter={!isTouchDevice ? () => handleDropdownToggle(element.id) : undefined}
                                    onMouseLeave={!isTouchDevice ? () => handleCloseDropdown(element.id) : undefined}
                                >
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id={`navbarDropdown-${element.id}`}
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded={dropdownOpen[element.id] ? "true" : "false"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDropdownToggle(element.id);
                                        }}
                                    >
                                        {element.name}
                                    </a>
                                    <div className={`dropdown-menu ${dropdownOpen[element.id] ? 'show' : ''}`} aria-labelledby={`navbarDropdown-${element.id}`}>
                                        {element.dropdownElements.map(dropdownElement => (
                                            <a key={dropdownElement.id} className="dropdown-item" href={dropdownElement.link} target={dropdownElement.newTab ? "_blank" : "_self"} rel={dropdownElement.newTab ? "noopener noreferrer" : undefined}>
                                                {dropdownElement.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )
                        ))}
                        {/* Search Button */}
                        <li className="nav-item">
                            <Link to="/meklet" className="nav-link">
                                <i className="fas fa-search"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    } else {
        return (
            <nav className='navbar navbar-expand-lg navbar-el' ref={navbarRef}>
                {/* Logo */}
                <Link to="/">
                    <img className='wpage_logo' src={logo} alt="Logo" />
                </Link>
                {/* Toggler icon for small screens */}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <img src={menuIcon} width="20" height="20" alt="Menu Icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '20px !important' }}>
                    <ul className="navbar-nav ml-auto">
                        {navbarElements.map(element => (
                            element.type === 'standart' ? (
                                <li key={element.id} className="nav-item">
                                    <a href={element.link} className="nav-link" target={element.newTab ? "_blank" : "_self"} rel={element.newTab ? "noopener noreferrer" : undefined}>
                                        {element.name}
                                    </a>
                                </li>
                            ) : (
                                <div
                                    key={element.id}
                                    className={`nav-item dropdown ${dropdownOpen[element.id] ? 'show' : ''}`}
                                    onMouseEnter={!isTouchDevice ? () => handleDropdownToggle(element.id) : undefined}
                                    onMouseLeave={!isTouchDevice ? () => handleCloseDropdown(element.id) : undefined}
                                >
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id={`navbarDropdown-${element.id}`}
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded={dropdownOpen[element.id] ? "true" : "false"}
                                        onClick={() => handleDropdownToggle(element.id)} // Toggle on click
                                    >
                                        {element.name}
                                    </a>
                                    <div className={`dropdown-menu ${dropdownOpen[element.id] ? 'show' : ''}`} aria-labelledby={`navbarDropdown-${element.id}`}>
                                        {element.dropdownElements.map(dropdownElement => (
                                            <a key={dropdownElement.id} className="dropdown-item" href={dropdownElement.link} target={dropdownElement.newTab ? "_blank" : "_self"} rel={dropdownElement.newTab ? "noopener noreferrer" : undefined}>
                                                {dropdownElement.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            )
                        ))}
                        {/* Search Button */}
                        <li className="nav-item">
                            <Link to="/meklet" className="nav-link">
                                <i className="fas fa-search"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
};

export default Navbar;