import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import backendAddress from '../panel_data/components/BackendAddress';
import LoadingAnimation from '../components/LoadingAnimation';
import ImageControllerLighthouse from '../components/ImageControllerLighthouse'; // Import the ImageControllerLighthouse component

const RenderGallery = ({NotificationManager}) => {
  const [contentJson, setContentJson] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]); // Manage images here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${backendAddress}/api/getgaleriju/${id}`);
        console.log('Fetched content:', response.data);
        setContentJson(response.data);
        setImages(response.data.photos.map(photo => `${backendAddress}/public${photo}`)); // Extract images
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNotFound(true);
        } else {
          console.error('Error fetching page content:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [id]);

  useEffect(() => {
    if (contentJson) {
      const numericId = parseInt(id, 10);
      if (Number.isInteger(numericId)) {
        const newLink = `${window.location.origin}/galerija/${contentJson.link}`;
        window.history.pushState({}, '', newLink);
      }
    }
  }, [contentJson, id]);

  if (notFound) {
    return <Navigate to="/navatrasta" />;
  }

  const CopyLinkToClipboard = () => {
    const linkToCopy = `${window.location.origin}/galerija/${contentJson.id}`;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        console.log('Link copied to clipboard:', linkToCopy);
        NotificationManager.success('Saite ir veiksmīgi nokopēta!', 'Īsā saite');
      })
      .catch(err => {
        console.error('Failed to copy the link: ', err);
        NotificationManager.error('Neizdevās nokopēt saiti!', 'Īsā saite');
      });
  };

  const formatDateInRigaTime = (dateString) => {
    const date = new Date(dateString);
  
    const options = {
      timeZone: 'Europe/Riga',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
  
    const formatter = new Intl.DateTimeFormat('lv', options);
    return formatter.format(date);
  };

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  return (
    <div className='zd_container_2' style={{marginTop: '30px', marginBottom: '30px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>
      <Helmet>
        <title>{isLoading ? ('...') : ( contentJson.title )} | Jēkabpils Valsts ģimnāzija</title>
      </Helmet>
      {isLoading ? (
        <LoadingAnimation/>
      ) : (
        <div>
          <h1 className='zd_title'><center>{contentJson.title}</center></h1>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
            {contentJson.photos.map((photo, index) => (
              <img
                key={index}
                src={`${backendAddress}/public${photo}`}
                alt={`Gallery image ${index + 1}`}
                onClick={() => handleImageClick(index)} // Use index to identify the image
                style={{ width: '350px', height: 'auto', margin: '10px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}
              />
            ))}
          </div>
          <div style={{marginTop: '50px', borderTop: '1px solid #ddd', paddingTop: '30px'}}>
            <p style={{ margin: '0 0 5px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Publicēts:</span> {formatDateInRigaTime(contentJson.published)}</p>
            <p style={{ margin: '0 0 5px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Kategorija:</span> galerija</p>
            <p style={{ margin: '0 0 5px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Fotogrāfiju skaits:</span> {contentJson.photos.length}</p>
            <p style={{ margin: '0 0 15px', fontSize: '16px', color: '#555' }}><span style={{ fontWeight: 'bold' }}>Autors:</span> {contentJson.author}</p>
            <p style={{ margin: '0 0 5px', fontSize: '16px' }}>
              <a onClick={CopyLinkToClipboard} style={{ cursor: 'pointer', color: '#6e0b0d', textDecoration: 'underline' }}>
                Īsā saite
              </a>
            </p>
          </div>
        </div>
      )}
      <ImageControllerLighthouse
        images={images}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
};

export default RenderGallery;