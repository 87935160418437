import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const CookiePolicyLogs = () => {
    const [cookiesAllowed, setCookiesAllowed] = useState(false);

    useEffect(() => {
        const allowed = Cookies.get('cookiesAllowed');
        setCookiesAllowed(allowed);
    }, []);

    const allowCookies = () => {
        Cookies.set('cookiesAllowed', true, { expires: 30 });
        setCookiesAllowed(true);
    }

    return (
        <div>
            {!cookiesAllowed && (
                <div class="cookie_policy_container">
                    <p>Mēs izmantojam sīkfailus un līdzīgas tehnoloģijas, lai šī vietne darbotos pareizi, analizētu trafiku un optimizētu jūsu pārlūkošanas pieredzi. Turpinot pārlūkot vietni, jūs piekrītat šādu sīkdatņu izmantošanai.</p>
                    <button onClick={allowCookies}>Labi</button>
                </div>
            )}
        </div>
    );
};

export default CookiePolicyLogs;